import React from 'react';

function Form({ handleSubmit, onSubmit, maxWidth = '20rem', children }) {
  return (
    <form style={{ maxWidth }} onSubmit={handleSubmit(onSubmit)}>
      {children}
    </form>
  );
}

export default Form;
