import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import {store} from './redux/store/index';
import { PersistGate } from 'redux-persist/integration/react';
import {persistStore} from "reduxjs-toolkit-persist";
import NotificationProvider from "./modules/gameScreen/components/NotificationContext/NotificationProvider";

import App from './app/App';
let persistor = persistStore(store)

ReactDOM.render(
    <NotificationProvider>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
    </NotificationProvider>,
  document.getElementById('root')
);
