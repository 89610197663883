import React from 'react';
import { toast } from 'react-toastify';
import './CustomNotifications.css';

const defaultOptions = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    draggable: true,
    progress: undefined,
    className:'blurBackground'
};

export class ToastHelperCustomNotification {
    static customNotification(msg, extraOptions = {}) {
        const { icon,isOA, ...options } = extraOptions;

        const notificationOptions = {
            ...defaultOptions,
            ...options,
        };

        return toast(
            <CustomNotification icon={icon} isOA={isOA} {...notificationOptions}>
                {msg}
            </CustomNotification>,
            notificationOptions,
        );
    }
}

const CustomNotification = ({ children, icon, style,isOA }) => (

    <div className='custom-notification-creating'>
        {icon &&
            <div className="notification-icon-design" style={{ color: `${isOA ? "#5F50EC" : "#01B636" }` }}>
                <div className='icon-container-notification'>
                    {icon}
                </div>
            </div>
        }
        <div className="custom-notification" style={{...style, backgroundColor: `${isOA ? "#5F50EC" : "#01B636" }` }}>
            <div className="notification-text-custom">{children}</div>
        </div>
    </div>
);