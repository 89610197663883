import {AuthHelper} from "./auth.helper";
export class HttpHelper {
    static async sendRequest(url, data = {}, method = "GET", headers = {}, appendApiUrl = false) {
        method = method.toUpperCase();

        if (appendApiUrl && url[0] !== "/") {
            url = `/${url}`;
        }

        const params = {
            method,
            headers: { ...headers },
        };

        try {
            params.headers["Authorization"] = `Bearer ${await AuthHelper.getToken()}`;

            if (method === "GET") {
                url = HttpHelper.appendQueryParams(url, data);
            } else {
                params.body = JSON.stringify(data);
                params.headers["Content-Type"] = "application/json";
            }

            const result = await fetch( (process.env.REACT_APP_URL) + url, params);

            if (!result.ok) {
                console.error(`Request failed with status ${result.status}`);
            }

            const contentType = result.headers.get("Content-Type");

            if (contentType && contentType.includes("application/json")) {
                return await result.json();
            } else {
                return await result.text();
            }
        } catch (error) {
            console.error("Error during request:", error);
            throw error;
        }
    }

    static appendQueryParams(url, data = {}) {
        const keys = Object.keys(data);

        if (keys.length) {
            url += "?";
        }

        const params = [];

        keys.forEach((key) => {
            if (typeof data[key] !== "undefined") {
                params.push(`${key}=${encodeURIComponent(data[key])}`);
            }
        });

        return url + params.join("&");
    }
}


// import {AuthHelper} from "./auth.helper";
// import {ToastHelper} from "./toast.helper";
//
// export class HttpHelper {
//     /**
//      * Send request to server
//      *
//      * @param url
//      * @param data
//      * @param method
//      * @param headers
//      * @param appendApiUrl
//      */
//     static async sendRequest(
//         url,
//         data = {},
//         method= "GET",
//         headers = {},
//         appendApiUrl= false
//     ){
//         method = method.toUpperCase()
//
//         if (appendApiUrl && url[0] !== "/") {
//             url += "/"
//         }
//
//         const params = {
//             method,
//             headers: Object.assign({}, headers),
//         }
//
//         params.headers["Authorization"] = `Bearer ${await AuthHelper.getToken()}`
//
//
//         if (method === "GET") {
//             url = HttpHelper.appendQueryParams(url, data)
//         } else {
//             params.body = JSON.stringify(data)
//             params.headers["Content-Type"] = "application/json"
//         }
//
//         let result
//
//         try {
//             result = await fetch(
//                 (process.env.REACT_APP_URL) + url,
//                 params
//             )
//         } catch (e) {
//             console.log({e});
//             return {}
//         }
//
//         const response = await result.json()
//
//
//         if (result.ok) {
//                 // ToastHelper.info(response)
//                 return response
//
//             }
//         return {}
//     }
//
//     /**
//      * Append query param to url and get new url
//      *
//      * @param url
//      * @param data
//      */
//      static appendQueryParams(url, data= {}) {
//         const keys = Object.keys(data)
//
//         if (keys.length) {
//             url += "?"
//         }
//
//         const params = []
//
//         // https://stackoverflow.com/questions/7842547/request-parameter-losing-plus-sign
//         keys.forEach(key => {
//             if (typeof data[key] !== "undefined") {
//                 params.push(`${key}=${encodeURIComponent(data[key])}`)
//             }
//         })
//
//         return url + params.join("&")
//     }
// }
