import React, { useEffect, useState } from 'react';
import {
    Avatar,
    Button,
    Grid,
    IconButton,
} from "@mui/material";
import { useForm } from "react-hook-form";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { useParams } from "react-router-dom";

import { Form } from "../../shared/components";
import { auth, db, storage } from "../../shared/services/firebase";
import { doc, getDoc } from "firebase/firestore";
import { VALIDATION_CONFIG } from "../../config/validation.config";
import { useLoginForm } from "../../modules/auth/hooks";
import { firestoreKeys } from "../../shared/utils/constants";
import { updateUser } from "../../shared/services/userService";

import './userDetailPage.css';
import useToast from "../../shared/hooks/useToast";
import {ToastHelper} from "../../helpers/toast.helper";
import {HttpHelper} from "../../helpers/http.helper";
import {ToastHelperCustomNotification} from "../CustomNotifications/CustomNotifications";
import PersonIcon from "@mui/icons-material/Person";
import useLoader from "../hooks/useLoader";
import {validatePassword} from "../../shared/utils/helpers";


const UserDetailPage = () => {

    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        imageAsFile: null,
        image: null,
        departments: [],
        departmentalId: '',
        divisionId: '',
        firstName: '',
        lastName: '',
        isImageSelected: false,
        divisions: [],
        dynamicHeight: window.innerHeight,
    });

    const { control, setValue, register, handleSubmit, formState: { errors, isValid } } = useForm({ mode: 'onChange' });

    const { handleLogin } = useLoginForm();
    const { showSuccess, showError } = useToast();
    const { id } = useParams();
    const { hideLoader, showLoader } = useLoader();

    useEffect(() => {
        const updateDynamicHeight = () => setFormValues((prevValues) => ({
            ...prevValues,
            dynamicHeight: window.innerHeight,
        }));
        window.addEventListener('resize', updateDynamicHeight);
        return () => window.removeEventListener('resize', updateDynamicHeight);
    }, []);

    const handleUploadImage = async () => {
        if (!formValues.imageAsFile) {
            console.error('No image selected');
            return;
        }
        const uploadTask = storage.ref(`/profileImages/${formValues.imageAsFile.name}`).put(formValues.imageAsFile);
        try {
            await uploadTask;
            const imageUrl = await storage.ref("profileImages").child(formValues.imageAsFile.name).getDownloadURL();
            return imageUrl;
        } catch (error) {
            console.error('Image upload error:', error);
            throw error;
        }
    };

    const getDivisionsData = async (id) => {
        try {
            const divisionsSnapshot = await db.collection(firestoreKeys.ORGANIZATIONS)
                .doc(userData?.organizationId)
                .collection(firestoreKeys.DEPARTMENTS)
                .doc(id)
                .collection(firestoreKeys.DIVISIONS)
                .get();

            const divisionsData = divisionsSnapshot.docs.map((element) => element.data());

            setFormValues((prevValues) => ({
                ...prevValues,
                divisions: divisionsData,
            }));

        } catch (e) {
            console.log('Error getting divisions data:', e);
        }
    };

    const getDepartments = async (userData) => {
        try {
            setIsLoading(true);
            showLoader()
            const departmentsList = [];
            const orgId = userData?.organizationId;
            const departmentsSnapshot = await db.collectionGroup(firestoreKeys.DEPARTMENTS)
                .where('organizationId', '==', orgId)
                .get();
            departmentsSnapshot.forEach((doc) => {
                departmentsList.push(doc.data());
            });

            let departmentsData = [...formValues.departments, ...departmentsList];

            setFormValues((prevValues) => ({
                ...prevValues,
                departments: departmentsData,
            }));

            setIsLoading(false);
            hideLoader();
        } catch (e) {
            console.log('Error getting departments:', e);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                showLoader()
                const docRef = doc(db, "users", `${id}`);
                const snapshot = await getDoc(docRef);
                const userData = snapshot.data();
                setUserData(userData);

                if (userData.details.firstName !== '') {
                    setFormValues((prevState) => ({
                        ...prevState,
                        firstName: userData.details.firstName
                    }));
                }

                if (userData.details.lastName !== '') {
                    setFormValues((prevState) => ({
                        ...prevState,
                        lastName: userData.details.lastName
                    }));
                }

                if (userData.details.password !== '') {
                    setFormValues((prevState) => ({
                        ...prevState,
                        password: userData.details.password
                    }));
                }
                if (userData.details.email !== '') {
                    setFormValues((prevState) => ({
                        ...prevState,
                        email: userData.details.email
                    }));
                }

                setIsLoading(false);
                hideLoader()
                await getDepartments(userData);
            } catch (e) {
                console.log('Error getting user data:', e);
            }
        };

        fetchData();
    }, [id]);

    const handleDepartments = async (event) => {
        const id = event.target.value;
        setFormValues((prevValues) => ({
            ...prevValues,
            departmentalId: id,
        }));
        await getDivisionsData(id);
    };

    const handleImageAsFile = (e) => {
        const image = e.target.files[0];
        if (image) {
            setFormValues((prevValues) => ({
                ...prevValues,
                imageAsFile: image,
            }));
            const imgPath = `C:\\fakepath\\${image.name}`;
            setValue("image", imgPath, { shouldValidate: true });
            const imageUrlDumy = URL.createObjectURL(image);
            setFormValues((prevValues) => ({
                ...prevValues,
                image: imageUrlDumy,
                isImageSelected: true,
            }));
        }else{
            setFormValues((prevValues) => ({
                ...prevValues,
                isImageSelected: false,
            }));
        }
    };

    const handleUpdate = async (data) => {

        if(!formValues.isImageSelected){
            ToastHelper.error("Please Select Image in order to continue.");
            return
        }
        try {
            setIsLoading(true);
            showLoader()
            const imageUrl = await handleUploadImage();

            const postData = {
                id: id,
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                image: imageUrl,
                departmentId: formValues.departmentalId,
                divisionId: formValues.divisionId,
                email: userData?.details?.email,
            };

            const userEmail = userData?.details?.email;
            const userPassword = userData.details.password;

            await auth().signInWithEmailAndPassword(userEmail, userPassword);
            const user = auth().currentUser;
            await user.updatePassword(formValues.password);

            await updateUser(postData);
            let confirmationPostData = {
                id: id,
                email: userData?.details?.email,
            };

            handleLogin({ email: userData?.details?.email, password: formValues?.password })
                .then( async () => {
                    await HttpHelper.sendRequest("/send-confirmation-mail", { ...confirmationPostData }, "POST");
                    // showSuccess("You are now a Player at FireAgilElements");
                    ToastHelperCustomNotification.customNotification("Confirmation email send!", { icon: <PersonIcon/>, isOA:false });
                    setIsLoading(false);
                    hideLoader()
                })
                .catch(() => {
                    setIsLoading(false);
                    hideLoader()
                });
        } catch (e) {
            console.log('Error updating user:', e);
        }
    };

    const handleCameraClick = () => {
        document.getElementById('imageInput').click();
    };

    return (
        <Grid container spacing={2} style={{ backgroundColor: '#E6E9F4', height: formValues.dynamicHeight + 'px' }}>
            <Grid item xs={12} className='user-details-page'>
                <Grid item xs={12} className='user-detail-page-inParent'>
                    <Grid item xs={12}>
                        <div className='teamMetrix-dashboard-heading'>
                            <div>
                                <h1 style={{
                                    fontWeight: 'bolder',
                                    color: 'white',
                                    marginTop: '60px',
                                    marginLeft: '30px',
                                    textTransform: 'capitalize'
                                }}>
                                    {userData?.organization?.companyName || 'Company'}
                                </h1>
                            </div>
                            <div style={{ alignSelf: 'center', position: 'relative', marginRight: '65px' }}>
                                <Avatar sx={{ width: 120, height: 120 }} alt="profileImage" src={formValues.image}/>
                                <div className='avatar-image-user'>
                                    <input
                                        type="file"
                                        id="imageInput"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        onChange={handleImageAsFile}
                                    />
                                    <IconButton style={{ color: 'black' }} disableRipple onClick={handleCameraClick}>
                                        <CameraAltOutlinedIcon style={{
                                            backgroundColor: '#01B636',
                                            borderRadius: '50%',
                                            padding: '4px',
                                            color: 'white'
                                        }}/>
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12} className='inputFieldsContainer'>
                    <div>
                        <Form maxWidth={"inherit"} handleSubmit={handleSubmit} onSubmit={handleUpdate}>
                            <div className='inputFieldDesign-userDetails'>
                                <div className="Fieldxxx">
                                    <label htmlFor='firtName' className='user-detail-label'>
                                        First name <sup>*</sup>
                                    </label>
                                    <input
                                        className='user-detail-inputFields'
                                        id='firtName'
                                        placeholder="First name"
                                        value={formValues.firstName}
                                        onChange={(e) => setFormValues((prevValues) => ({
                                            ...prevValues,
                                            firstName: e.target.value
                                        }))}
                                    />
                                </div>
                                <div className="Fieldxxx">
                                    <label htmlFor='lastName' className='user-detail-label'>
                                        Last name <sup>*</sup>
                                    </label>
                                    <input
                                        className='user-detail-inputFields'
                                        type='text'
                                        id='lastName'
                                        placeholder="Last name"
                                        required
                                        value={formValues.lastName}
                                        onChange={(e) => setFormValues((prevValues) => ({
                                            ...prevValues,
                                            lastName: e.target.value
                                        }))}
                                    />
                                </div>
                            </div>
                            <div className='inputFieldDesign-userDetails'>
                                <div className="Fieldxxx">
                                    <label htmlFor='email' className='user-detail-label'>
                                        Email
                                    </label>
                                    <input
                                        className='user-detail-inputFields'
                                        id='email'
                                        placeholder="Email"
                                        value={formValues.email}
                                        onChange={(e) => setFormValues((prevState) => ({
                                            ...prevState,
                                            email: e.target.value
                                        }))}
                                        disabled={true}
                                    />
                                </div>
                                <div className="Fieldxxx">
                                    <label htmlFor='password' className='user-detail-label'>
                                        Password <sup>*</sup>
                                    </label>
                                    <input
                                        className='user-detail-inputFields'
                                        type='password'
                                        id='password'
                                        placeholder="Enter password"
                                        required
                                        value={formValues.password}
                                        disabled={userData.details?.isRegistered}

                                        onChange={(e) => setFormValues((prevState) => ({
                                            ...prevState,
                                            password: e.target.value
                                        }))}
                                    />
                                    {validatePassword(formValues.password) !== true && (
                                        <span className="error-text">
                                            {validatePassword(formValues.password)}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className='inputFieldDesign-userDetails'>
                                <div className="Fieldxxx">
                                    <label htmlFor='dept' className='user-detail-label'>
                                        Department
                                    </label>
                                    <select
                                        className='user-detail-inputFields'
                                        id='dept'
                                        placeholder="Department"
                                        onChange={handleDepartments}
                                        value={formValues.departmentalId}
                                    >
                                        <option aria-label="None" value=""/>
                                        {formValues.departments.map((dept) => (
                                            <option key={dept.id} value={dept.id}>{dept.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="Fieldxxx">
                                    <label htmlFor='div' className='user-detail-label'>
                                        Division
                                    </label>
                                    <select
                                        className='user-detail-inputFields'
                                        id='div'
                                        placeholder="Division"
                                        disabled={formValues.divisions.length === 0}
                                        onChange={(e) => {
                                            setFormValues((prevValues) => ({
                                                ...prevValues,
                                                divisionId: e.target.value
                                            }));
                                        }}
                                        value={formValues.divisionId}
                                    >
                                        <option aria-label="None" value=""/>
                                        {formValues.divisions.map((dept) => (
                                            <option key={dept.id} value={dept.id}>{dept.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='user-detail-edit'
                                 style={{display: 'flex', gap: '21px', marginTop: '3%'}}>
                                <Button
                                    className='user-detail-btn'
                                    style={{
                                        backgroundColor: ( isLoading || (!formValues.firstName || !formValues.lastName || !formValues.password || !formValues.image || validatePassword(formValues.password) !== true)) ? "grey" : "#EF7E14",
                                        color: '#ffffff',
                                        width: '20%',
                                        height: '48px',
                                        textTransform: 'capitalize',
                                        fontSize: 'larger',
                                        fontWeight: 'bolder'
                                    }}
                                    type='submit'
                                    variant='contained'
                                    disabled={ isLoading || (!formValues.firstName || !formValues.lastName || !formValues.password || !formValues.image || validatePassword(formValues.password) !== true)}
                                >
                                    Update
                                </Button>
                                <Button
                                    className='user-detail-btn'
                                    style={{
                                        backgroundColor: '#ffffff',
                                        color: 'black',
                                        border: '1px solid black',
                                        width: '20%',
                                        height: '48px',
                                        textTransform: 'capitalize',
                                        fontSize: 'larger',
                                        fontWeight: 'bolder'
                                    }}
                                    onClick={() => {
                                        // Handle cancel button click
                                    }}
                                    variant='contained'
                                >
                                    Cancel
                                </Button>
                            </div>

                        </Form>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default UserDetailPage;