const useLoader=()=>{
    const loader = document.querySelector('.loaderOuter');

    const showLoader =  ()=> {
         loader.classList.remove('loader--hide');
    }

    const hideLoader =   ()=> {
         loader.classList.add('loader--hide');
    }


    return {showLoader,hideLoader};
};
export default useLoader;
