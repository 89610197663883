import {auth} from "../shared/services/firebase";
import firebase from "firebase/compat";
export class FirebaseHelper {
    static getCurrentUser(){
        try {
            return auth().currentUser
        } catch (e) {
            return null
        }
    }
    static async getCurrentUserTokn() {
        return new Promise((resolve, reject) => {
            const currentUser = firebase.auth().currentUser
            if (currentUser) resolve(currentUser)
            else {
                const unsubscribe = firebase.auth().onAuthStateChanged(user => {
                    unsubscribe()
                    resolve(user)
                }, reject)
            }
        })
    }


    static async getCurrentUserToken() {
        try {
            let accessToken= await FirebaseHelper.getCurrentUserTokn();

            let token =accessToken.multiFactor.user.accessToken;

            return token;

        } catch (e) {
            return null
        }
    }
}