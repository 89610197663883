import { createTheme } from '@mui/material';

export default createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ed344d',
      light: '#fff',
      dark: '#726fd6',
      contrastText: '#fff',
      text: '#333333',
      alternativeLight: '#E5E9F6'
    }
  }
});
