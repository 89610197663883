import { useAtom } from 'jotai';
import { showToastAtom, toastAtom } from '../state/atoms';

function useToast() {
  const [, setShowToast] = useAtom(showToastAtom);
  const [, setToast] = useAtom(toastAtom);

  const showError = (message, duration) => {
    showToast('error', duration, message);
  };

  const showWarning = (message, duration) => {
    showToast('warning', duration, message);
  };

  const showInfo = (message, duration) => {
    showToast('info', duration, message);
  };

  const showSuccess = (message, duration) => {
    showToast('success', duration, message);
  };

  const showToast = (severity, duration = 6000, message) => {
    setShowToast(true);
    setToast({ severity, duration, message });
  };

  return { showError, showWarning, showInfo, showSuccess };
}

export default useToast;
