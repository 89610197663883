import {db, dbr} from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {firestoreKeys, itemStatus, stages} from '../utils/constants';
import React from "react";
import {generateGameCode, setItemColor, uuid} from "../utils/helpers";
import {commitWorkItem} from "./workItemService";

export async function createSession(sessionId, data,ball, drillId) {
    let sessionID=sessionId;

  const checkDuplicateSessionId  = async ()=>{
      let sessionRef=db.collection(firestoreKeys.SESSIONS).doc(sessionID);
      const doc = await sessionRef.get();
      if(!doc.exists){
          return sessionID;
      }else{
          sessionID=generateGameCode();
          checkDuplicateSessionId();
      }
  }
    await checkDuplicateSessionId();

    if(data?.playScale){
        sessionID= sessionID;
        const colors = ['#FF9E80FF' , '#80D8FFFF' , '#B9F6CAFF']
        for(let i=0;i<3;i++){
            const team = `Team ${i+1}`
            let uniqueTeamId = generateGameCode();
            uniqueTeamId = uniqueTeamId;
            let newdata = {...data, teamName : team ,orderOfTeam: i, teamColor : colors[i]}
            newdata.status = 'in-lobby';
            newdata.rounds[0].currentStage = 15;
            db.collection(firestoreKeys.SESSIONS).doc(sessionID).collection(firestoreKeys.SCALETEAMS).doc(uniqueTeamId).collection(firestoreKeys.BALLS).doc(ball.ballId).set(ball);
            db.collection(firestoreKeys.SESSIONS).doc(sessionID).collection(firestoreKeys.SCALETEAMS).doc(uniqueTeamId).collection(firestoreKeys.ROUNDS).doc('0').set(data.rounds[0]);
            db.collection(firestoreKeys.SESSIONS).doc(sessionID).collection(firestoreKeys.SCALETEAMS).doc(uniqueTeamId).collection(firestoreKeys.TIMERS).doc('0').set(data.rounds[0].timer);
            db.collection(firestoreKeys.SESSIONS).doc(sessionID).collection(firestoreKeys.SCALETEAMS).doc(uniqueTeamId).collection(firestoreKeys.ROUND_ITEMS).doc(sessionID).set({roundsWorkItem:[]});
            delete newdata.rounds;
            db.collection(firestoreKeys.SESSIONS).doc(sessionID).collection(firestoreKeys.SCALETEAMS).doc(uniqueTeamId).set(newdata);
        }
    }
    if(drillId === 0){
        db.collection(firestoreKeys.SESSIONS).doc(sessionID).collection(firestoreKeys.BALLS).doc(ball.ballId).set(ball);
    }
     db.collection(firestoreKeys.SESSIONS).doc(sessionID).collection(firestoreKeys.ROUNDS).doc('0').set(data.rounds[0]);
     db.collection(firestoreKeys.SESSIONS).doc(sessionID).collection(firestoreKeys.TIMERS).doc('0').set(data.rounds[0].timer);
     db.collection(firestoreKeys.SESSIONS).doc(sessionID).collection(firestoreKeys.ROUND_ITEMS).doc(sessionID).set({roundsWorkItem:[]});
     delete data.rounds;
     db.collection(firestoreKeys.SESSIONS).doc(sessionID).set(data);
     return sessionID;
}

export function updateSessionState(sessionId, sessionState) {
    return db.collection(firestoreKeys.SESSIONS).doc(sessionId).update({
        status: sessionState
    });
}
export async function updateNewChallengeTimerProperties(sessionId, roundIndex,roundTimer){
    return await db.collection(firestoreKeys.SESSIONS).doc(sessionId).collection(firestoreKeys.TIMERS).doc(roundIndex.toString()).set(roundTimer);
}
export async function updateSessionPropertiesAfterReview(sessionId,roundIndex,roundData){
    return await db.collection(firestoreKeys.SESSIONS).doc(sessionId).collection(firestoreKeys.ROUNDS).doc(roundIndex.toString()).set(roundData);
}
export function updateSessionConfiguration(sessionId, configuration) {
    return db.collection(firestoreKeys.SESSIONS).doc(sessionId).update({
        configuration: configuration
    });
}

export async function startNewRound(sessionId, stage, session,players, ballsCollection,roundsCollection,workItems,timer) {

    let newSession={...session};

    // await Promise.all(ballsCollection?.map(async (data) => {
    //     if (data.currentPlayerId !== 'waterTank' && data.currentPlayerId !== 'packageBox') {
    //         await deleteBallFromCollection(sessionId, data);
    //     }
    // }));

    const activeIndex = roundsCollection.length - 1
    const roundData ={...roundsCollection[activeIndex]};
    let fireStarter = roundData.roles.fireStarter || '';

    //this was for the old challenge stage

    if (stage ===  stages.CHALLENGE){
        const uniqueId = uuid();
        await addBallsToCollection(sessionId,{ currentPlayerId: fireStarter, futurePlayerId: '', receivedPlayerIds: [], isTraveling: false , ballId: uniqueId , ballTravelTime : roundData.currentTravelTime,heatLevel:0});
    }


    const round={
        note:[],
        completedWorkItems:[],
        reviewWorkItems:[],
        reviewTasksCount:0,
        areMetricsShown: roundData.areMetricsShown,
        currentStage: stage,
        score: 0,
        defeactedBallCount: 0,
        goldenCount:0,
        huddleSentBackItems:0,
        completedTasksCount:0,
        valueOfWorkItems:0,
        noOfWorkItemsCompleted:0,
        roundTaskScore:0,
        providedScore: 0,
        commitedItemsScore:0,
        estimate: 0,
        currentTravelTime: roundData.currentTravelTime,
        selectedTankerMan: roundData.selectedTankerMan,
        selectedBoxMan: roundData.selectedBoxMan,
        roles: roundData.roles,
        skills: roundData.skills,
        obstacles: roundData.obstacles,
        executionerTutorialIsPlayed:false,
        goldenTravelTime: {
            startTravelTime: null,
        },
        timer: {
            showGameTimer: false,
            showPlanningTimer: false,
            isPaused: false,
            isExhausted:false,
            isPausedByGameView: false,
            currentStageEndTime: null,
            currentStateTimeRemaining: 100000,
            startedByFireStarter:false,
            meta: {
                createdDate: firebase.firestore.Timestamp.fromDate(new Date())
            }
        },
        gameDisplays: {
            areRulesShown: roundData.gameDisplays.areRulesShown,
            areStatisticsShown: roundData.gameDisplays.areStatisticsShown
        },
        commitmentDisplays: {
            areFireBallsAvgShown: roundData?.commitmentDisplays?.areFireBallsAvgShown,
        },
        sentBackItems:[],
        skippedWorkItems:[],
        roundsWorkItem:roundData.roundsWorkItem,
        meta: {
            createdDate: firebase.firestore.Timestamp.fromDate(new Date())
        }
    };

    if (roundData.roles.productOwner){
        const workItemsData = [];
        newSession.activeWorkItem.map((id) =>{
            const item =  workItems.find((data) => data.id === id);
            workItemsData.push(item);
        })
        workItemsData.map((workItem) =>{
            const updateWorkItem={...workItem, itemStatus: itemStatus.backlog,color:setItemColor(workItem),isItemSentBack: false,unCompletedItem:true};
            commitWorkItem(sessionId, updateWorkItem);
        })
        newSession.activeWorkItem = [];
    }

    newSession.tapOn="";
    round.commitedItemsScore = newSession.activeWorkItem.length;
    const roundIndex  = activeIndex + 1

    // await db.collection(firestoreKeys.SESSIONS).doc(sessionId).collection(firestoreKeys.TIMERS).doc(roundIndex.toString()).set(round.timer);
    await updateSessionProperties(sessionId,newSession);


    if(roundsCollection?.length === 1){
        const updatedPosition=[2,3,4,5,6,9,10,11,12];
        const batch = db.batch();
        players.forEach((player,index) => {
            const playerRef = db.collection(firestoreKeys.SESSIONS).doc(sessionId).collection(firestoreKeys.PLAYERS).doc(player.id);
            batch.update(playerRef, { position: updatedPosition[index] });
        });
        await batch.commit();
    }
    await updateSessionPropertiesAfterReview(sessionId,roundIndex,round);
    return await updateNewChallengeTimerProperties(sessionId, roundIndex,round.timer)
}

export async function addWorkItemsOrder(sessionId,sortedArray) {
    return await db.collection(firestoreKeys.SESSIONS).doc(sessionId).collection(firestoreKeys.SORTEDWORKITEMS).doc(sessionId.toString()).set(sortedArray);
}

export async function updateSessionProperties(sessionId, data) {
    let newSession={...data};
        delete newSession?.workItemsForTeams;
    return await db.collection(firestoreKeys.SESSIONS).doc(sessionId).update(newSession);
}

export async function updateSessionMiddleSmoke(sessionId, data) {
    return await db.collection(firestoreKeys.SESSIONS).doc(sessionId).update(data);
}
export async function updateSessionTrackExecutioner(sessionId, data) {
    return await db.collection(firestoreKeys.SESSIONS).doc(sessionId).update(data);
}
export async function resetSessionTemperate(sessionId, data) {
    return await db.collection(firestoreKeys.SESSIONS).doc(sessionId).update(data);
}
export async function updateRefinementType(sessionId, data) {
    return await db.collection(firestoreKeys.SESSIONS).doc(sessionId).update(data);
}
export async function setPlayersWithFlowAssistants(sessionId, data) {
    return await db.collection(firestoreKeys.SESSIONS).doc(sessionId).collection(firestoreKeys.PLAYERS).doc(data.id).set(data);
}

export async function updatePlayersPositions(sessionId, data) {
    return await db.collection(firestoreKeys.SESSIONS).doc(sessionId).collection(firestoreKeys.PLAYERS).doc(data.id).update(data);
}

export async function updateBallsCollectionProperties(sessionId, data) {
    return await db.collection(firestoreKeys.SESSIONS).doc(sessionId).collection("balls").doc(data.ballId).update(data);
}
export async function updatePlayerProperties(sessionId, data) {
    return await db.collection(firestoreKeys.SESSIONS).doc(sessionId).collection(firestoreKeys.PLAYERS).doc(data.id).update(data);
}
export async function updateWorkItemStatus(sessionId, data) {
    return await db.collection(firestoreKeys.SESSIONS).doc(sessionId).collection(firestoreKeys.WORK_ITEMS).doc(data.id).update(data);
}
export async function updateRefinementStatus(sessionId, data) {
    return await db.collection(firestoreKeys.SESSIONS).doc(sessionId).update(data);
}
export async function updateRoundsCollectionProperties(sessionId,index, data) {
    return await db.collection(firestoreKeys.SESSIONS).doc(sessionId).collection("rounds").doc(index.toString()).update(data);
}

// export async function updatePlayerBlackBallTapped(sessionId, players) {
//     const batch = db.batch();
//
//     for (const player of players) {
//         const playerRef = db.collection(firestoreKeys.SESSIONS).doc(sessionId).collection(firestoreKeys.PLAYERS).doc(player.id);
//         batch.update(playerRef, player);
//     }
//
//     await batch.commit();
// }

export async function updateRoundsTimerCollectionProperties(sessionId,index, data) {
    return await db.collection(firestoreKeys.SESSIONS).doc(sessionId).collection(firestoreKeys.TIMERS).doc(index.toString()).update(data);
}

export async function updateRoundsItemsArray(sessionId, data) {
    return await db.collection(firestoreKeys.SESSIONS).doc(sessionId).collection(firestoreKeys.ROUND_ITEMS).doc(sessionId).update(data);
}

export async function addBallsToCollection(sessionId, data) {
    return await db.collection(firestoreKeys.SESSIONS).doc(sessionId).collection("balls").doc(data.ballId).set(data);
}

// export async function addRoundToCollection(sessionId, data) {
//     return await db.collection(firestoreKeys.SESSIONS).doc(sessionId).collection("rounds").doc(data.ballId).set(data);
// }

export async function deleteBallFromCollection(sessionId, data) {
    return await db.collection(firestoreKeys.SESSIONS).doc(sessionId).collection("balls").doc(data.ballId).delete();
}

export async function deleteWorkItemsForTeam(sessionId, itemId) {
    return await db.collection(firestoreKeys.SESSIONS).doc(sessionId).collection("workItemsForTeam").doc(itemId).delete();
}

export async function createOrganization(data) {
    return await db.collection(firestoreKeys.ORGANIZATIONS).doc(data.id).set(data);
}
export async function createTeam(data) {
    return await db.collection(firestoreKeys.TEAMS).doc(data.id).set(data);
}

export async function createDepartment(data){
    return await db.collection(firestoreKeys.ORGANIZATIONS).doc(data.organizationId).collection(firestoreKeys.DEPARTMENTS).doc(data.id).set(data);
}
export async function createDivision(data){
    return await db.collection(firestoreKeys.ORGANIZATIONS).doc(data.organizationId).collection(firestoreKeys.DEPARTMENTS).doc(data.departmentId).collection(firestoreKeys.DIVISIONS).doc(data.id).set(data);
}

export async function updateUserCount(data) {
    return await db.collection(firestoreKeys.ORGANIZATIONS).doc(data.id).update({membersCount: firebase.firestore.FieldValue.increment(-1)});
}

export async function updateOrganizationUser(data) {
    return await db.collection(firestoreKeys.USERS).doc(data.id).update(data);
}
export async function updateAEQRating(data) {
    return await db.collection(firestoreKeys.SESSIONS).doc(data.id).update(data);
}

// export async function updateRoundsObstacles(sessionId,roundIndex,obstacles) {
//     return await db.collection(firestoreKeys.SESSIONS).doc(sessionId).collection(firestoreKeys.ROUNDS).doc(roundIndex.toString()).update({ obstacles:  obstacles });
// }
