import React from 'react';
import { FormControl, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

import './FormTextInput.css';

function FormTextInput({ name, label, type, control, defaultValue = '', required, rules, errors, disabled, variant = 'outlined', ...props }) {
    const hasError = !!errors[name];

    return (
        <FormControl fullWidth variant={variant} className='textInputContainer'>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={{
                    ...rules,
                    required: required,
                }}
                render={({ field }) => (
                    <TextField
                        {...field}
                        id={name}
                        type={type}
                        required={required}
                        helperText={hasError ? errors[name]?.message : null}
                        label={label}
                        error={hasError}
                        disabled={disabled}
                        {...props}
                    />
                )}
            />
        </FormControl>
    );
}

export default FormTextInput;
