import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const isDarkModeAtom = atom(false);
export const headerMenuKeyAtom = atom('sessions');
export const menuDrawerOpenAtom = atom(false);

export const userAtom = atomWithStorage('user', null);
export const userRecordAtom = atomWithStorage('userRecord', null);

export const showToastAtom = atom(false);
export const toastAtom = atom({ severity: 'info', duration: 0, message: '' });
