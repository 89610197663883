import { createSlice} from "@reduxjs/toolkit";

const initialState={
    user: null
};

export const userSlice=createSlice({
    name:'user',
    initialState:initialState,
    reducers:{
        setUser: (initialState, action)=> {
            const {payload} = action;
            initialState.user = {
            ...payload.details,
                permissions : payload.permissions
            }
            return initialState;
        }
    }
})
export const {setUser}=userSlice.actions;

export default userSlice.reducer;
