import React, { useState } from 'react';
import NotificationContext from './NotificationContext';

const NotificationProvider = ({ children }) => {
    const [message, setMessage] = useState('');

    const showNotification = (msg , timerLimit= 6000) => {

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        setMessage(msg);

        const timeoutId = setTimeout(() => {
            setMessage('');
        }, timerLimit);
    };

    const contextValue = {
        message,
        showNotification,
    };

    return (
        <NotificationContext.Provider value={contextValue}>
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationProvider;
