import { configureStore } from '@reduxjs/toolkit';
import {persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import UserReducer from '../userSlice';
const persistConfig = {
    key: 'root',
    version: 1,
    storage,
}
const persistedReducer = persistReducer(persistConfig, UserReducer)
export const store = configureStore({
    reducer: {user:persistedReducer},
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware(),
})
