import React from 'react';
import { Switch, FormControl } from '@mui/material';
import { Controller } from 'react-hook-form';

import './FormSwitch.css';

function FormSwitch({ name, defaultValue, control, disabled, ...props }) {
  return (
    <FormControl fullWidth className='switchContainer'>
      <Controller
        name={name}
        control={control}
        render={({field}) => <Switch {...field} defaultChecked={defaultValue} disabled={disabled} {...props} />}
      ></Controller>
    </FormControl>
  );
}

export default FormSwitch;
