import dayjs from 'dayjs';
import {
  updateRoundsCollectionProperties,
  updateSessionProperties,
  updateSessionTrackExecutioner,
} from "../services/sessionService";
import {firestoreKeys, itemColors} from "./constants";
import {addWorkItemsOrder} from "../services/sessionService";
import {HttpHelper} from "../../helpers/http.helper";
import notification from "../../assets/sounds/notification.mp3";
import ballPassSound from "../../assets/sounds/neighbourPass.wav";
import {db} from "../services/firebase";

export const generateGameCode = () => {
  var text = '';
  var possible = '0123456789';

  for (var i = 0; i < 4; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export const isValidEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
};

export const formatTimestampToDate = (timestamp = 0) => {
  const date = new Date(timestamp.seconds * 1000);
  return dayjs(date).format('DD/MM/YYYY');
};

export const formatDateTime = (timestamp = 0) => {
  const date = new Date(timestamp.seconds  * 1000);
  const x= dayjs(date).format("DD-MMM-YYYY")
  const y = date.toLocaleString().substr(11,8)
  return `${x} / [${y}]`;
}

export const determineBestRound = (roundsCollection) => {
  let max = 0;
  for (let i = 0; i < roundsCollection?.length; i++) {
    if (roundsCollection[i]?.score > max) {
      max = roundsCollection[i]?.score;
    }
  }
  return max;
};

export const determineWorstRound = (roundsCollection) => {
  let min = Number.POSITIVE_INFINITY;
  for (let i = 0; i <= roundsCollection?.length; i++) {
    if (roundsCollection[i]?.score < min) {
      min = roundsCollection?.score;
    }
  }
  return min;
};

export const detemineAverageScore = (roundsCollection) => {
  let total = 0;
  for (let i = 0; i <= roundsCollection?.length - 1; i++) {
    total += roundsCollection[i]?.score;
  }
  return (total / roundsCollection?.length).toFixed(2);
};

export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

export const disappointedCustomers=(roundsCollection)=>{

  let commitedItemScoreCount= 0;
  let completedTaskCount= 0;
  let disappointedCustomerCount=0

    for(let i=0; i < roundsCollection.length; i++){

      if(roundsCollection[i]?.roles?.isCommitmentStageSelected){
        commitedItemScoreCount+=roundsCollection[i].commitedItemsScore;
        completedTaskCount+=roundsCollection[i].completedTasksCount;
      }

      if(commitedItemScoreCount !== 0){
        if( completedTaskCount !== commitedItemScoreCount ){
          disappointedCustomerCount+=1;
        }
      }
  }
  return disappointedCustomerCount;
}

export const shuffleItemsIfDontExist = (workItems,id) =>{
  const uniqueId = `sortedItems${id}`;
  const hasSortedArray  = localStorage.getItem(uniqueId);
  const parsedSortedArray = JSON.parse(hasSortedArray);
  if (!parsedSortedArray){
    const sortOrderWithID = [];
    let shuffled = workItems
        .map(value => ({ ...value, sort:Math.floor(Math.random() * 1000)}))
        .sort((a, b) => a.sort - b.sort);
        shuffled.map((value) =>{
          sortOrderWithID.push({
            "sortId":value.sort,
            "workItemId":value.id
          })
        })
    addWorkItemsOrder(id, {sortOrderWithIDs:sortOrderWithID});
    localStorage.setItem(uniqueId, JSON.stringify(sortOrderWithID));
    return shuffled;
  }else {
    return  workItems.map((item)=>{
      return {
        ...item,
        sort: parsedSortedArray.find(({workItemId})=>workItemId===item.id)?.sortId||0
      }
    }).sort((a, b)=>a.sort-b.sort);
  }
}

export const setItemColor = (item)  =>{
  if (item.isItemSentBack === true) {
    item.color = itemColors.sentBackWorkItems;
  } else if (item.showMissingInfo === true) {
    item.color = itemColors.acceptedWithMissingInfo;
  } else if (item.showMissingInfo === false &&
      item.showWho === true &&
      item.showValue === true &&
      item.showAmount === true) {
    item.color = itemColors.committedItems;
  } else if (item.showMissingInfo === false ||
      item.showWho === false ||
      item.showValue === false ||
      item.showAmount === false) {
    item.color = itemColors.unCompleted;
  }
  return item.color;
}


export const determineTotalRevenue = (workItems,roundsCollection) => {
  let total = 0;
  const thisIs = roundsCollection?.map((round, index)=>{
    console.log(round)
    if(round?.roles?.isAutomatingScoreKeeping){
      round.completedWorkItems.map((id) =>{
        const workItem = workItems.find((data) => data.id === id);
        if (workItem?.showValue){
          const amount = workItem.value.replace(/[^a-zA-Z0-9 ]/g, '').trim();
          total = total + parseInt(amount)
        }
      })
    }else{
      total+=round.valueOfWorkItems;
    }
  });
  return total;
};

export const exchangeAccessToken = async (code) => {

  try{
    const data = {
      code,
    };

    const rawResponse=HttpHelper.sendRequest('/linkedin-signIn',{...data},"POST");

    return rawResponse;

  } catch (e){
    return e
  }
}

export const getNeighbors= async (id,playersArr,players) =>{

    const sortedPlayers = playersArr?.sort((a, b) => a.position - b.position);
    const index = sortedPlayers.findIndex(p => p.id === players);
    const previousIndex = (index - 1 + sortedPlayers.length) % sortedPlayers.length;
    const nextIndex = (index + 1) % sortedPlayers.length;

    let previousPlayer= sortedPlayers[previousIndex].id;
    let nextPlayer= sortedPlayers[nextIndex].id;

    return [previousPlayer,nextPlayer]

}

export const generateRandomNumbers = (min, max, times,rPlayers) => {

  const randoms = [];
  const randPlayerIds=[];

  for (let i = 0; i < times; i++) {
    let r= Math.floor(Math.random() * (max - min) + min)
    if(randoms.indexOf(r) === -1) {
      randoms.push(r)
    }
  }

  for(let i = 0; i < randoms.length; i++) {
    let num=randoms[i];
    if(rPlayers[num]?.id){
      randPlayerIds.push(rPlayers[num]?.id);
    }
  }
  return randPlayerIds
}

// export const generateMiddleSmokePositions = (min, max, times,rPlayers) => {
//
//   const randoms = [];
//   const randPlayerIds=[];
//
//   for (let i = 0; i < times; i++) {
//     let r= Math.floor(Math.random() * (max - min) + min)
//     if(randoms.indexOf(r) === -1) {
//       randoms.push(r)
//     }
//   }
//
//   for(let i = 0; i < randoms.length; i++) {
//     let num=randoms[i];
//     if(rPlayers[num]?.id){
//       randPlayerIds.push(rPlayers[num]?.position-1);
//     }
//   }
//   return randPlayerIds
// }


export const stringAvatar=(name)=> {
  return {
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

export function validatePassword(value) {
  if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(value)) {
    return 'Minimum 8 characters with 1 uppercase letter, 1 number, and 1 special character';
  }
  return true;
}

export async function updateExecutioner(session, players, activeRound, id, workItems, updateRoundRolesProperty) {
  if (session?.configuration?.firePlus) {
    const playersList = players?.filter((player) => !player.isFlowAssistant && activeRound.roles.productOwner !== player.id && !session.trackExecutioner.includes(player.id) && activeRound.roles.botMaster !== player.id);
    if (playersList.length === 0) {
      const sessionObj = { ...session, trackExecutioner: [] };
      await updateSessionTrackExecutioner(id, sessionObj);
      return;
    }

    const randomIndex = Math.floor(Math.random() * playersList.length);
    const selectedPlayer = playersList[randomIndex];
    const sessionObj = { ...session, trackExecutioner: [...session.trackExecutioner, selectedPlayer.id], ...session.configuration.executionerShown = false };

    await Promise.all([
      updateRoundRolesProperty({ target: { value: selectedPlayer.id } }, 'executioner', workItems),
      updateSessionTrackExecutioner(id, sessionObj)
    ]);
  }
}

function getRandomPlayerId(array) {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex].id;
}

export const setWorkItemStatusColor = (item) => {
  if (item.isItemSentBack === true) {
    item.color = '0xFFFFA500';
  } else if (
      item.showMissingInfo === false &&
      item.showWho === true &&
      item.showValue === true &&
      item.showAmount === true
  ) {
    item.color = '0xFF388E3C';
  } else if (
      item.showMissingInfo === false ||
      item.showWho === false ||
      item.showValue === false ||
      item.showAmount === false
  ) {
    item.color = '0xFFFFFF00';
  } else if (
      item.showMissingInfo === true ||
      item.showWho === true ||
      item.showValue === true ||
      item.showAmount === true
  ) {
    item.color = '0xFF388E3C';
  }
  return item.color;
};

export async function handleAssignProductOwner(id, session, players, activeRound,roundsCollection ) {
    let newSession = {...session};
    const activeIndex = roundsCollection.length - 1;
    const funnelPlayer = players.filter((player) => activeRound.roles.executioner !== player.id && activeRound.roles.fireStarter !== player.id && player.isFlowAssistant !==true )
    const funnelPlayerId = getRandomPlayerId(funnelPlayer)

    activeRound.roles['productOwner'] = funnelPlayerId;

    const index = newSession.players.findIndex((player) => player.id === activeRound.roles.productOwner);

    newSession.players[index] = {...newSession.players[index], "isFunnel": true , "isFlowAssistant": true}

    await db.collection(firestoreKeys.SESSIONS).doc(id).collection(firestoreKeys.PLAYERS).doc(funnelPlayerId).update({isFunnel: true, isFlowAssistant : true});
    if (activeRound.roles.scoreKeeper)
      if (activeRound.roles.timeKeeper === activeRound.roles.productOwner) {
        activeRound.roles.isAutomatingTimeKeeping = true;
      }

    if (activeRound.roles.scoreKeeper === activeRound.roles.productOwner) {
      activeRound.roles.isAutomatingScoreKeeping = true;
    }
  await updateRoundsCollectionProperties(id, activeIndex, activeRound);
  await updateSessionProperties(id, newSession);
}

export async function handleAssignBotMaster(id, players, activeRound,roundsCollection){
  const activeIndex = roundsCollection.length - 1;
  const botPlayer = players.filter((player) => activeRound.roles.executioner !== player.id && activeRound.roles.productOwner !== player.id )
  activeRound.roles['botMaster'] = botPlayer[0]?.id;

  await updateRoundsCollectionProperties(id, activeIndex, activeRound);
}

export const playNotificationSound = () => {
  const notificationSoundFile = notification;
  new Audio(notificationSoundFile).play();
};

export const playBallPassSound = () => {
  const ballPassSoundFile = ballPassSound;
  new Audio(ballPassSoundFile).play();
};