import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAtom } from 'jotai';
import { auth } from './firebase';
import { userAtom, userRecordAtom } from '../state/atoms';

function useAuthService() {
  const [, setUser] = useAtom(userAtom);
  const [, setUserRecord] = useAtom(userRecordAtom);

  React.useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged((user) => setUser(user));
    return unsubscribe;
    // eslint-disable-next-line
  }, []);

  function signIn(email, password) {
    return firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        return auth().signInWithEmailAndPassword(email, password);
      });
  }

  function signInWithToken(token) {
    return firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          return auth().signInWithCustomToken(token);
        });
  }

  function signOut() {
    setUser(null);
    setUserRecord(null);
    return auth().signOut();
  }

  function sendForgotPasswordEmail(email) {
    return auth().sendPasswordResetEmail(email);
  }

  return { signIn, signOut, sendForgotPasswordEmail, signInWithToken };
}

export default useAuthService;
