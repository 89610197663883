import React from 'react';
import { Checkbox, FormControl, FormControlLabel, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Controller } from 'react-hook-form';
import { pink } from '@mui/material/colors';

import './FormCheckbox.css';

function FormCheckbox({ name, label, defaultValue, labelPlacement = 'end', tooltipPlacement = 'right', control, disabled, tooltip, colorPink }) {
  return (
    <FormControl fullWidth className='checkboxContainer'>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <FormControlLabel
            {...field}
            value={name}
            control={
              <Checkbox
                sx={
                  colorPink
                    ? {
                        'color': pink[800],
                        '&.Mui-checked': {
                          color: pink[600]
                        }
                      }
                    : {}
                }
                checked={field.value || false}
              />
            }
            label={label}
            labelPlacement={labelPlacement}
            disabled={disabled}
          />
        )}
      ></Controller>
      {tooltip && (
        <Tooltip title={tooltip} placement={tooltipPlacement}>
          <InfoOutlinedIcon fontSize='small' />
        </Tooltip>
      )}
    </FormControl>
  );
}

export default FormCheckbox;
