import Logo from './logo.png';
import ChairIcon from './chair.png';
import TableIcon from './table.png';
import SwipeExample from './swipe-example.png';
import GameCircle from './game-circle.png';
import Clock from './clock.png';
import Funnel from './funnel.png';
import FunnelPink from './funnelPink.png';
import TutorialImageOne from './tutorialImage1.png';
import TutorialImageTwo from './tutorialImage2.png';
import TutorialImageThree from './tutorialImage3.jpg';
import TutorialImageFour from './tutorialImage4.jpg';
import TutorialSlideOneNew from './Challange.svg';
import TutorialSlideTwoTennisCourt from './tenisCourtTutorial.png';
import ReflectionSlideImage from './Reflection.svg';
import ReviewSlideImage from './Review.svg';
import InProgressSlideImage from './InProgress.svg';
import PlaningSlideImage from './Planning.svg';
import Aeq from './aeq.png';
import AgileFire  from './agileFireLogo.png';
import Spoke  from './spoke.png';
import TTwoImage from './Player_Team.svg';
import SuccessTick from './SuccessTick.png';
import BePatientImg from './be_patent_screen.jpg';
import leftImageSlide from './Host3.png';
import pinkFunnel from './funnelPink.png';
import funnelTutorialSlide2 from './funnelTutorialSlide2.svg';
import funnelTutorialSlide3 from './funnelTutorialSlide3.svg';
import funnelTutorialSlide4 from './funnelTutorialSlide4.svg';
import funnelTutorialSlide5 from './funnel-tutorial-5-step1.svg';
import funnelTutorialSlide5b from './funnel-tutorial-5b-step2.svg';
import funnelTutorialSlide6 from './funnelTutorialSlide6.svg';
import funnelTutorialSlide7 from './funnelTutorialSlide7.svg';
import funnelTutorialSlide8 from './funnelTutorialSlide8.svg';
import funnelTutorialbacklogb from './funnel-tutorial-8-step 1.svg';
import funnelTutorialSlide9 from './funnelTutorialSlide9.gif';
import funnelTutorialSlide10 from './funnelTutorialSlide10.gif';
import funnelTutorialSlide11 from  './funnelTutorialSlide11.png';
import executioner from './executionar.png';
import executionerTutorialSlide2 from './executionerTutorialSlide2.gif';
import executionerTutorialSlide3 from './executionerTutorialSlide3.gif';
import executionerTutorialSlide4 from './executionerTutorialSlide4.gif';
import bouncerTutorial5 from './bouncerTutorial5.gif';
import bluePic from './blue.png';
import pinkPic from './pink.png';
import blackBall from './blackBall.png';
import HqFire from "./HQ-PNG.png";
import handPointer from "./handpointer.svg";
import chains from "./chains.png";
import crackedBallpng from './crackedBall.png';
import crackedBall from "./crackedBall.gif";
import smokeLevel1 from "./smokeLevel1.png";
import smokeLevel2 from "./smokeLevel2.png";
import smokeLevel3 from "./smokeLevel3.png";
import crackedBallImage from './crackedBallImage.png';
import blueCircle from "./blueCircle.png";
import whiteCircle from "./whiteBall.png";
import emoji1 from "./emoji1.png";
import emoji2 from "./emoji2.png";
import emoji3 from "./emoji3.png";

import grey1x from "./pink-1x.png";
import greyDone from "./pink-ok.png";
import yellow1x from "./yellow-1x.png";
import yellow2x from "./yellow-2x.png";
import yellowDone from "./yellow-ok.png";
import red1x from "./red-1x.png";
import red2x from "./red-2x.png";
import red3x from "./red-3x.png";
import redDone from "./red-ok.png";
import middleSmoke1 from "./middleSmoke1.png";
import middleSmoke2 from "./middleSmoke2.png";
import middleSmoke3 from "./middleSmoke3.png";

import singleMob from './singleMob.png';
import multipleMob from './multipleMob.png';
import dollerBag from './dollerBag.png';
import revenyeBag from './revenuebag.png';
import alertImage from './alertImage.png';
import crowdCheering from './crowdCheering.png';
import thermometer from './thermometer.png';
import bouncer from './executionar.png';
import ballSpeedImg from './ballSpeed.png';
import muscleSlapGif from './muscleSlap.gif';
import waterTanker from './waterTanker.png';
import boxPackage from './boxPackage.png';
import fireball from './fireBall.svg';
import GroupEmoji from './Group.svg';
import FrameEmoji from './Frame.svg';
import tanis_ball from './tanis_ball.svg';
import questionMark from './questionMark.svg';
import manLogo from './manLogo.png';
import illustration from './illustration.svg';

import executionerSvg from './executionarSvg.svg';
import manPointing from './manPointing.svg';
import servedIcon from './servedIcon.svg';
import unservedIcon from './unServedIcon.svg';
import bloqIcon from './bloqIcon.svg';
import dummyWorkItem from './dummyWorkItem.svg'
import moneyBacklogo from './moneyback.svg';
import funnel_illustration from './fuunel_illustration.svg';
import awardIcon from './awardIcon.svg';
import tennis_ball3 from './ball_tennis3.svg'

import ballLogoVertical from './ballLogoVertical.svg';

import roundTimerOuterCircleDots from './roundTimerOuterCircleDots.svg';

import roundCircle from './roundCircle.svg';

import fireManLogo from './fireManLogo.svg';
import tankerManLogo from './tankerManLogo.svg';
import boxManLogo from './boxManLogo.svg';
import funnelManLogo from './funnelManLogo.svg';
import executionerManLogo from './executionerManLogo.svg';
import smokeLevel1Svg from './smokeLevel1.svg';
import smokeLevel2Svg from './smokeLevel2.svg';
import smokeLevel3Svg from './smokeLevel3.svg';

import middleFireRound from './middleFireRound.svg';

import ballSpeed from './ballSpeed.svg';
import boxSvg from './boxSvg.svg';
import tankerSvg from './tankerSvg.svg';
import angryFacesEmjois from './angryFaces.svg';
import thermometerIcon from './thermometerIcon.svg';
import timeUpSvg from './timeUp.svg';
import timeOutSvg from './timeOut.svg';
import timerOuterRing from './timerOuterRing.svg';
import alertTimerSvg from './alertTimerSvg.svg';
import awardSvg from './awardSvg.svg';
import smileySvg from './smileySvg.svg';

import dots from './dots.svg';
import twoSplitBalls from './twoSplitBalls.svg'
import fiveSplitBalls from './fiveSplitBalls.svg'
import tenSplitBalls from './tenSplitBalls.svg'
import tennisBall from './tennisBall.png';
import bookmarkIcon from './bookmarkIcon.svg';
import refinementRequestImg from './refinementRequestImg.svg';
import lobbyGroupSvg from './lobbyGroupSvg.svg';
import lobbyPeopleIcon from './lobbyPeopleIcon.svg';
import lobbyLaptopIcon from './lobbyLaptopIcon.svg';
import lobbyQRIcon from './lobbyQRIcon.svg';
import lobbyFlowAssistantIcon from './lobbyFlowAssistantIcon.svg';
import lobbyCoachIcon from './lobbyCoachIcon.svg';
import refinementCancelImg from './refinementCancel.svg';
import blackDomAlertIcon from './blackDomAlertIcon.svg';
import calendarIcon from './calendarIcon.svg';
import medalIcon from './medalIcon.svg'
import starIcon from './starIcon.svg';
import emptyWaterTanker from './EmptyWaterTankerSvg.svg';
import emptyBoxSvg from './EmptyBoxSvg.svg';
import trainingDumbbell from './traingingDumbbell.png';
import tannisBall1 from './TennisBall1.svg';
import tannisBall2 from './TennisBall2.svg';
import bouncerTutorial from './bouncerTutorial.svg';
import arrowSvg from "./arrow-movment.gif";
import funnelActivatedSvg from './funnelActivatedSvg.svg';
import plainBall1 from './plain-ball-1.gif';
import plainBall2 from './plain-ball-2.gif';
import plainBall3 from './plain-ball-3.gif';
import heatBall1 from './heat-ball-1.gif';
import heatBall2 from './heat-ball-2.gif';
import heatBall3 from './heat-ball-3.gif';
import staticWaterTanker1 from './staticWaterTanker1.svg';
import staticWaterTanker2 from './staticWaterTanker2.svg';
import staticWaterTanker3 from './staticWaterTanker3.svg';
import testWaterTank from './testWaterTank.gif';
import funnelTutorialSvg from './funneltutorialSvg.svg';
import trendingArrow from "./trending_flat.svg";

import dashboardMan from './dashboardMan.svg';
import zigzagLine from './zigzagLine.png';
import dashboardbtm1 from './dashboardbtm1.svg';
import dashboardbtm2 from './dashboardbtm2.svg';
import dashboardbtm3 from './dashboardbtm3.svg';
import oa_svg from './OA.svg';
import agileLeaderSvg from './agileLeader.svg';
import totalOrgSvg from './totalOrgSvg.svg';
import createWorkItemBtn from './createWorkItemBtnSvg.svg';
import addUserSvg from './addUserSvg.svg';
import dateTimeSvg from './dateTimeSvg.svg';
import peopleSvg from './peopleSvg.svg';
import companySvg from './companySvg.svg';
import profileInfoEditSvg from './profileInfoEditSvg.svg';
import addDepartmentSvg from "./addDepartmentsSvg.svg";
import addDivisionSvg from './addDivisionSvg.svg';
import addNewTeamSvg from './addNewTeamSvg.svg';
import alarmCommitment from './alarmCommitment.svg';
import orangeFlag from './orangeFlag.svg';
import endSession from './endSession.svg';
import commitmentLockIcon from './commitmentLockIcon.svg';
import tankerSkillIcon from './tankerSkillIcon.svg';
import boxSkillIcon from './boxSkillIcon.svg';
import heaterSkillIcon from './heaterSkillIcon.svg';
import tankerNot from './TankerNot.svg';
import manDart from './manDart.svg';
import customerServed from './customerServed.svg';
import estimatePlan from './estimatePlan.svg';
import projectedIncomePlan from './projectedIncomePlan.svg';
import Maskgroup from './Maskgroup.svg';
import earthIcon from './earthIcon.png';
import windIcon from './windIcon.png';
import fireIcon from './fireIcon.png';
import waterIcon from './waterIcon.png';
import lobbyDrillPeople from './lobbyDrillPeople.svg';
import speedIcon from './speed-icon.svg';
import estimateDrill from './estimateDrill.svg';
import earthIconhead from './earthIconhead.svg';
import bookMarkYellow from './bookmarkYellow.svg';
import workItemEstimate from './workItemEstimate.svg';
import earthManDart from './earthManDart.svg';
import earthSvg from './earthSvg.svg';
import waterSvg from './waterSvg.svg';
import windSvg from './windSvg.svg';
import fireSvgg from './fireSvgg.svg';
import whiteBrain from './whiteBrain.png';
import brainGreen from './brainGreen.svg';
import level1Ball from './level1Ball.svg';
import level2ball from './level2Ball.svg';
import level3ball from './level3Ball.svg';
import level4ball from './level4Ball.svg';
import level3Grey from './level3Grey.svg';
import level4Grey from './level4Grey.svg';
import earthLevel2 from './earthLevel2.png';



export {
    Logo,
    ChairIcon,
    TableIcon,
    SwipeExample,
    GameCircle,
    Clock,
    Funnel,
    FunnelPink,
    TutorialImageOne,
    TutorialImageTwo,
    TutorialImageThree,
    TutorialImageFour,
    Aeq,
    AgileFire,
    Spoke,
    TutorialSlideOneNew,
    TutorialSlideTwoTennisCourt,
    ReflectionSlideImage,
    ReviewSlideImage,
    InProgressSlideImage,
    PlaningSlideImage,
    TTwoImage,
    SuccessTick,
    BePatientImg,
    leftImageSlide,
    pinkFunnel,
    funnelTutorialSlide2,
    funnelTutorialSlide3,
    funnelTutorialSlide4,
    funnelTutorialSlide5,
    trendingArrow,
    funnelTutorialSlide5b,
    funnelTutorialSlide6,
    funnelTutorialSlide7,
    funnelTutorialSlide8,
    funnelTutorialSlide9,
    funnelTutorialSlide10,
    funnelTutorialSlide11,
    funnelTutorialbacklogb,
    executioner,
    executionerTutorialSlide2,
    executionerTutorialSlide3,
    executionerTutorialSlide4,
    bluePic,
    pinkPic,
    blackBall,
    HqFire,
    handPointer,
    chains,
    crackedBall,
    crackedBallpng,
    smokeLevel1,
    smokeLevel2,
    smokeLevel3,
    crackedBallImage,
    blueCircle,
    emoji1,
    emoji2,
    emoji3,
    whiteCircle,
    grey1x,
    greyDone,
    yellow1x,
    yellow2x,
    yellowDone,
    red1x,
    red2x,
    red3x,
    redDone,
    middleSmoke1,
    middleSmoke2,
    middleSmoke3,
    singleMob,
    multipleMob,
    dollerBag,
    revenyeBag,
    alertImage,
    crowdCheering,
    thermometer,
    bouncer,
    ballSpeedImg,
    muscleSlapGif,
    waterTanker,
    boxPackage,
    fireball,
    GroupEmoji,
    FrameEmoji,
    tanis_ball,
    questionMark,
    manLogo,
    illustration,
    executionerSvg,
    manPointing,
    servedIcon,
    unservedIcon,
    bloqIcon,
    dummyWorkItem,
    moneyBacklogo,
    funnel_illustration,
    awardIcon,
    ballLogoVertical,
    roundTimerOuterCircleDots,
    roundCircle,
    middleFireRound,
    tennis_ball3,
    ballSpeed,
    boxSvg,
    tankerSvg,
    fireManLogo,
    executionerManLogo,
    funnelManLogo,
    boxManLogo,
    tankerManLogo,
    smokeLevel1Svg,
    smokeLevel2Svg,
    smokeLevel3Svg,
    timeUpSvg,
    timeOutSvg,
    thermometerIcon,
    angryFacesEmjois,
    timerOuterRing,
    alertTimerSvg,
    awardSvg,
    smileySvg,
    tenSplitBalls,
    fiveSplitBalls,
    twoSplitBalls,
    dots,
    tennisBall,
    bookmarkIcon,
    lobbyPeopleIcon,
    lobbyLaptopIcon,
    lobbyQRIcon,
    lobbyGroupSvg,
    lobbyFlowAssistantIcon,
    refinementRequestImg,
    refinementCancelImg,
    lobbyCoachIcon,
    blackDomAlertIcon,
    calendarIcon,
    medalIcon,
    starIcon,
    emptyWaterTanker,
    emptyBoxSvg,
    trainingDumbbell,
    tannisBall1,
    tannisBall2,
    bouncerTutorial,
    arrowSvg,
    bouncerTutorial5,
    funnelActivatedSvg,
    plainBall1,
    plainBall2,
    plainBall3,
    heatBall1,
    heatBall2,
    heatBall3,
    staticWaterTanker1,
    staticWaterTanker2,
    staticWaterTanker3,
    testWaterTank,
    funnelTutorialSvg,

    dashboardMan,
    zigzagLine,
    dashboardbtm1,
    dashboardbtm2,
    dashboardbtm3,
    oa_svg,
    agileLeaderSvg,
    totalOrgSvg,
    createWorkItemBtn,
    addUserSvg,
    companySvg,
    peopleSvg,
    dateTimeSvg,
    profileInfoEditSvg,
    addDepartmentSvg,
    addDivisionSvg,
    addNewTeamSvg,
    alarmCommitment,
    orangeFlag,
    endSession,
    commitmentLockIcon,
    heaterSkillIcon,
    tankerSkillIcon,
    boxSkillIcon,
    tankerNot,
    manDart,
    customerServed,
    estimatePlan,
    projectedIncomePlan,
    Maskgroup,
    earthIcon,
    windIcon,
    waterIcon,
    fireIcon,
    lobbyDrillPeople,
    speedIcon,
    estimateDrill,
    earthIconhead,
    bookMarkYellow,
    workItemEstimate,
    earthManDart,
    earthSvg,
    waterSvg,
    windSvg,
    fireSvgg,
    whiteBrain,
    brainGreen,
    level1Ball,
    level2ball,
    level3ball,
    level4ball,
    level3Grey,
    level4Grey,
    earthLevel2
};
