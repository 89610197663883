import { FirebaseHelper } from "./firebase.helper"

export class AuthHelper {

    static isLoggedIn() {
        return !!AuthHelper.getFirebaseUser()
    }

    static async getToken() {
        return await FirebaseHelper.getCurrentUserToken()
    }

    static getFirebaseUser() {
        return FirebaseHelper.getCurrentUser()
    }
}
