import React, {useEffect, useState} from 'react';
import {Button, Grid, IconButton} from "@mui/material";
import ApartmentIcon from '@mui/icons-material/Apartment';
import { useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {Avatar} from "@mui/material";

import {Form,FormTextInput} from "../../shared/components";
import {auth, db, storage} from "../../shared/services/firebase";
import './OAFillDetailsPage.css';
import {VALIDATION_CONFIG} from "../../config/validation.config";
import {useLoginForm} from "../../modules/auth/hooks";
import {useToast} from "../../shared/hooks";
import {doc, getDoc} from "firebase/firestore";
import {updateOrganizationUser} from "../../shared/services/userService";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import {ToastHelper} from "../../helpers/toast.helper";
import {HttpHelper} from "../../helpers/http.helper";
import {ToastHelperCustomNotification} from "../CustomNotifications/CustomNotifications";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";

const OAFillDetailPage=()=>{

    const [imageAsFile, setImageAsFile]=useState('')
    const [isImageSelected, setIsImageSelected] = useState(false);

    const [image, setImage]=useState()
    const {
        control,
        setValue,
        handleSubmit,
        formState: {errors, isValid}
    }=useForm({mode: 'onChange'});

    const [userData, setUserData]=useState({})

    const [isLoading , setIsloading] = useState(false);

    const [dynamicHeight, setDynamicHeight] = useState(window.innerHeight);

    useEffect(() => {
        // Function to update dynamic height based on window height
        const updateDynamicHeight = () => {
            setDynamicHeight(window.innerHeight);
        };

        window.addEventListener('resize', updateDynamicHeight);

        return () => {
            window.removeEventListener('resize', updateDynamicHeight);
        };
    }, []);


    const { showSuccess, showError } = useToast();

    const { handleLogin } = useLoginForm();

    let {id}=useParams();

    const handleUploadImage= async ()=>{
        if (imageAsFile==='') {
            console.error(`not an image, the image file is a ${typeof (imageAsFile)}`)
            return
        }

        const uploadTask=storage.ref(`/profileImages/${imageAsFile?.name}`).put(imageAsFile)

        try{
            await uploadTask;
            const imageUrl = await storage
                .ref("profileImages")
                .child(imageAsFile.name)
                .getDownloadURL();
            return imageUrl;
        } catch (error) {
            console.error('Image upload error:', error);
            throw error;
        }
    };

    const handleImageAsFile = (e) => {
        const image = e.target.files[0];
        if (image) {
            setImageAsFile(image);
            const imgPath = `C:\\fakepath\\${image.name}`;
            setValue("image", imgPath, { shouldValidate: true });
            const imageUrlDumy = URL.createObjectURL(image);
            setImage(imageUrlDumy);
            setIsImageSelected(true)
        }else{
            setIsImageSelected(false)
        }
    }


    useEffect(()=>{
        getUsersData(id);
    }, []);


    const getUsersData = async (id) => {
        try {
            const docRef = doc(db, "users", `${id}`);
            const snapshot = await getDoc(docRef);
            const userDataCopy = snapshot.data();

            const organizationSnapshot = await db.collection('organizations').where(`organizationOwner`, '==', id).get();
            const organizationData = organizationSnapshot.docs.map(doc => doc.data());

            setUserData((u) => ({
                ...u,
                organization: organizationData,
                userData: userDataCopy,
            }));
        } catch (error) {
            console.error("Error fetching user or organization data:", error);
        }
    };


    const handleUpdate = async (data)=>{
        if(!isImageSelected){
            ToastHelper.error("Please Select Image in order to continue.");
            return
        }
        setIsloading(true);
        const imageUrl = await handleUploadImage();
        setIsloading(false);

        let postData={
            id:id,
            email: userData?.userData?.details?.email,
            firstName:data.firstName,
            lastName:data.lastName,
            image:imageUrl,
            password:data.password
        }

        try {
            setIsloading(true);

            await auth().signInWithEmailAndPassword(userData?.userData?.details?.email, userData?.userData?.details?.password);

            const user = auth().currentUser;
            let confirmationPostData = {
                id: id,
                email: userData?.userData?.details?.email,
            };

            try {
                await user.updatePassword(data.password);
                updateOrganizationUser(postData);
                await HttpHelper.sendRequest("/send-confirmation-mail", { ...confirmationPostData }, "POST");
                await handleLogin({ email: userData?.userData?.details.email, password: data.password });
                ToastHelperCustomNotification.customNotification("Confirmation email send!", { icon:  <MarkEmailUnreadIcon/>, isOA:true });

                setIsloading(false);
            } catch (updatePasswordError) {
                setIsloading(false);
                console.error(updatePasswordError);
            }
        } catch (signInError) {
            setIsloading(false);
            console.error(signInError);
        }
    }

    const handleCameraClick = () => {
        document.getElementById('imageInput').click();
    };

    return (
        <Grid container spacing={2} style={{ backgroundColor: '#E6E9F4', height: dynamicHeight + 'px' }}>
            <Grid item xs={12} className='OA-details-page'>
                <Grid item xs={12} className='OA-detail-page-inParent'>
                    <Grid item xs={12}>
                        <div className='OAMatrix-dashboard-heading'>
                            <div>
                                <h1 style={{ fontWeight: 'bolder', color: 'white', marginTop: '60px', marginLeft: '30px', textTransform:'capitalize' }}>{userData?.organization?.companyName || 'Company'}</h1>
                            </div>
                            <div style={{ alignSelf: 'center', position: 'relative',marginRight:'65px' }}>
                                <Avatar
                                    sx={{ width: 120, height: 120 }}
                                    alt="profileImage"
                                    src={image || undefined}
                                >
                                    {image ? null : <ApartmentIcon style={{ fontSize: 70 }} />}
                                </Avatar>

                                <div className='OA-avatar-image-user'>
                                    <input
                                        type="file"
                                        id="imageInput"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        onChange={handleImageAsFile}
                                        required
                                    />
                                    <IconButton style={{ color: 'black' }} disableRipple onClick={handleCameraClick}>
                                        <CameraAltOutlinedIcon style={{backgroundColor:'#01B636',borderRadius:'50%',padding:'4px',color:'white'}}/>
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12} className='OA-inputFieldsContainer'>
                    <div>
                        <Form maxWidth={"inherit"} handleSubmit={handleSubmit} onSubmit={handleUpdate}>
                            <div className='formDiv'>
                                <div className='OA-inputFieldDesign-userDetails'>
                                    <div className='OA-Fieldxxx'>
                                        <label htmlFor='firtName' className='OA-user-detail-label'>
                                            First name <sup>*</sup>
                                        </label>
                                        <FormTextInput
                                            id='firtName'
                                            type='text'
                                            name='firstName'
                                            // label='First Name'
                                            required
                                            control={control}
                                            errors={errors}
                                            rules={{
                                                required: VALIDATION_CONFIG.required,
                                            }}
                                        />
                                    </div>
                                    <div className='OA-Fieldxxx'>
                                        <label htmlFor='lastName' className='OA-user-detail-label'>
                                            Last name <sup>*</sup>
                                        </label>
                                        <FormTextInput
                                            id='lastName'
                                            type='text'
                                            name='lastName'
                                            required
                                            control={control}
                                            errors={errors}
                                            rules={{
                                                required: VALIDATION_CONFIG.required,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='OA-inputFieldDesign-userDetails'>
                                    <div className='OA-Fieldxxx'>
                                        <label htmlFor='email' className='OA-user-detail-label'>
                                            Email
                                        </label>
                                        <FormTextInput
                                            name='email'
                                            id='email'
                                            type='email'
                                            control={control}
                                            errors={errors}
                                            value={userData?.userData?.details.email || "xyz@yopmail.com"}
                                            disabled={true}
                                        />
                                    </div>

                                    <div className='OA-Fieldxxx'>
                                        <label htmlFor='password' className='OA-user-detail-label'>
                                            Password <sup>*</sup>
                                        </label>
                                        <FormTextInput
                                            id='password'
                                            type='password'
                                            name='password'
                                            required
                                            control={control}
                                            errors={errors}
                                            rules={{
                                                required: VALIDATION_CONFIG.required,
                                                pattern: {
                                                    value: VALIDATION_CONFIG.password,
                                                    message: 'Invalid password. Password must include at least one number, one uppercase letter, one lowercase letter, and one special character. It should be between 8 to 20 characters long.',
                                                },
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='user-detail-edit' style={{ display: 'flex', gap: '21px', marginTop: '3%' }}>
                                    <Button
                                        className='OA-detail-btn'
                                        style={{
                                            backgroundColor: (!isValid || isLoading )  ?  "grey" : "#EF7E14" ,
                                            color: '#ffffff',
                                            width: '20%',
                                            height: '48px',
                                            textTransform: 'capitalize',
                                            fontSize: 'larger',
                                            fontWeight: 'bolder'
                                        }}
                                        type='submit'
                                        variant='contained'
                                        disabled={!isValid || isLoading}
                                    >
                                        Update
                                    </Button>
                                </div>
                            </div>
                        </Form>

                    </div>
                </Grid>
            </Grid>
        </Grid>
    );

}
export default OAFillDetailPage;
