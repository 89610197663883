import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { Typography, Grid } from '@mui/material';
import { PauseCircle, PlayCircle } from '@mui/icons-material';
import {updateRoundsTimerCollectionProperties} from '../../../services/sessionService';
import {Clock, timerOuterRing} from "../../../../assets/images";
import {Image} from "mui-image";
import TimeUpAlert from "../../../../modules/gameScreen/components/AlertBox/TimeUpAlert";
import AlertComponent from "../../../../modules/gameScreen/components/AlertComponent/AlertComponent";
import {db} from "../../../services/firebase";
import {firestoreKeys} from "../../../utils/constants";

const CountdownTimer = React.forwardRef((props, ref = null ) => {

  const { session, id, completeAction = () => {}, isHostView, roundsCollection , isPlanning = false, timer,isGameView,isGameStage = false } =props;
  const reference = React.useRef();
  const isCompletedRef = React.useRef(false);

  const isClickedThroughUI = React.useRef(false);

  const [timerObj, setTimer] = React.useState(true);

  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    if (isMounted){
      if (timerObj) {
        handlePause();
      }else {
        handleResume();
      }
    }else {
      setIsMounted(true);
    }
    // eslint-disable-next-line
  }, [timerObj]);


  const [isTimerComplete] = React.useState();
  const currentRound = roundsCollection[roundsCollection.length - 1];

  React.useEffect(() => {
    if (timer.isPaused && !isClickedThroughUI.current) {
      pauseTimer();
    }

    if (!timer.isPaused && !isClickedThroughUI.current) {
      resumeTimer();
    }
    // eslint-disable-next-line
  }, [timer?.isPaused]);

  const calculateRemainingTime = React.useMemo(() => {
    let output = Date.now();
    if (timer.isPaused && timer.isPausedByGameView) {
      return  output + timer.currentStateTimeRemaining;
    } else if (timer.currentStageEndTime === null) {
      const activeIndex = roundsCollection.length - 1
      const timerObject = {...timer};
      timerObject.currentStageEndTime = output + timerObject.currentStateTimeRemaining;

      isHostView && updateRoundsTimerCollectionProperties(id,activeIndex,timerObject);
      return  output + timer.currentStateTimeRemaining;
    } else {
      return  output + timer.currentStageEndTime - output;
    }
  },[timer.currentStateTimeRemaining]);

  // const calculateRemainingTimeForHost = React.useMemo(() =>{
  //   let output = Date.now();
  //   if (timer.isPaused && timer.isPausedByGameView) {
  //     return  output + timer.currentStateTimeRemaining;
  //   } else if (timer.currentStageEndTime === null) {
  //     const activeIndex = roundsCollection.length - 1
  //     const timerObject = {...timer};
  //     timerObject.currentStageEndTime = output + timerObject.currentStateTimeRemaining;
  //
  //     isHostView && updateRoundsTimerCollectionProperties(id,activeIndex,timerObject);
  //     return  output + timer.currentStateTimeRemaining;
  //   } else {
  //     return  output + timer.currentStageEndTime - output;
  //   }
  // },[timer]);

  const handlePause = async () => {
    if (isHostView) {
      isClickedThroughUI.current = true;
      const current=ref?.current ? ref?.current?.state?.timeDelta?.total : reference?.current?.state?.timeDelta?.total;
      const activeIndex=roundsCollection.length-1;
      const newTimerObj = await db.collection(firestoreKeys.SESSIONS).doc(id).collection(firestoreKeys.TIMERS).doc(activeIndex.toString()).get();

      const newTimerObject = newTimerObj.data();
      const timerObject = {...newTimerObject};
      timerObject.isPaused=true;
      timerObject.isPausedByGameView=true;
      timerObject.currentStateTimeRemaining=timer.currentStateTimeRemaining;

      isHostView && updateRoundsTimerCollectionProperties(id, activeIndex, timerObject);

      setTimeout(() => {
        isClickedThroughUI.current = false
      }, 100);
    }
    return ref?.current ? ref.current?.pause() :  reference.current?.pause();

  };

  const handleResume = () => {
    if (isHostView){
      isClickedThroughUI.current = true;
      const current = ref?.current ? ref?.current?.state?.timeDelta?.total :  reference?.current?.state?.timeDelta?.total;
      const activeIndex = roundsCollection.length - 1
      const timerObject = {...timer};

      if (timerObject.isPaused){
        timerObject.isPaused = false;
      }
      timerObject.isPausedByGameView = false;
      timerObject.currentStageEndTime = Date.now() + current;


      isHostView && updateRoundsTimerCollectionProperties(id,activeIndex,timerObject);
      setTimeout(() => {
        isClickedThroughUI.current = false
      }, 100);
    }
    return ref?.current ? ref.current?.start() :  reference.current?.start();
  };



  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      if(isGameView && !isPlanning){
        return <AlertComponent shouldLast={7000} isCommitment={false} title={'Time is Up'} emojis={null} isTimeUpAlert={true} mainImage={timerOuterRing} msg={null} />
      }else{
        return <Typography variant='h4'>Time is up</Typography>;
      }
    } else {
      return (
        <Typography variant='h5' className='timer'>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </Typography>
      );
    }
  };

  const onTimerComplete = () =>{
    if (isCompletedRef.current === false){
      isCompletedRef.current =  setTimeout(() =>{
        completeAction();
        isCompletedRef.current = false;
      },0);
    }

  }

  React.useEffect(()=>{
    return ()=>{
      clearTimeout(isCompletedRef.current);
    };
  }, []);

  const resumeTimer = () =>{
    setTimer(false);
  }

  const pauseTimer = () =>{
    setTimer(true);
  }

  return (
    <Grid container spacing={2} justifyContent='center' alignItems="center">
      <Grid item display="flex" flexDirection="column" justifyContent='center' alignContent="center" >
        {isHostView && !isGameStage &&
            <Image height={25}  src={Clock} duration={0} />
        }
      </Grid>
      <Grid item>
        <Countdown autoStart={false} date={calculateRemainingTime} renderer={renderer} ref={ref ? ref : reference} onComplete={completeAction} />
      </Grid>
      <Grid item>
        {isHostView && !isGameStage &&
            <>
            {timer.isPaused  ? (
                  <PlayCircle color='primary' fontSize='medium' onClick={isHostView ? resumeTimer : () => {}} />
              ) : (
                  <PauseCircle color={isTimerComplete ? 'disabled' : 'primary'} fontSize='medium' onClick={isTimerComplete || !isHostView ? () => {} : pauseTimer} />
              )}
            </>
        }

      </Grid>
    </Grid>
  );
})

export default CountdownTimer;
