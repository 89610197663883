import React from 'react';
import { LoadingButton } from '@mui/lab';
import { FormControl } from '@mui/material';

function FormSubmitButton({ label, loading, startIcon, disabled, className, variant = 'contained', color = 'primary' }) {
  return (
    <FormControl style={{width:'50%'}} className={className}>
      <LoadingButton loading={loading} startIcon={startIcon} variant={variant} color={color} disabled={disabled} type='submit'>
        {label}
      </LoadingButton>
    </FormControl>
  );
}

export default FormSubmitButton;
