import {db} from './firebase';
import {firestoreKeys} from '../utils/constants';
import firebase from "firebase/compat";
import {ToastHelper} from "../../helpers/toast.helper";


export function getUser(id) {
    return db.collection(firestoreKeys.USERS).doc(id).get()
        .then((res) => ({
            ...res.data(),
            id
        }));
}
export function getOrganization(id) {
    return db.collection(firestoreKeys.ORGANIZATIONS).doc(id).get()
        .then((res) => ({
            ...res.data(),
            id
        }));
}

export async function createUser(data) {
    return await db.collection(firestoreKeys.USERS).doc(data.id).set(data);
}

export async function updateUser(data) {

    let obj = {
        'details': {
            'email': data.email,
            'firstName': data.firstName,
            'lastName': data.lastName,
            'imageUrl': data.image,
        },
        'departmentId': data.departmentId,
        'divisionId': data.divisionId,
    }
    return await db.collection(firestoreKeys.USERS).doc(data.id).update(obj);
}

export async function updateOrganizationUser(data) {

    let obj = {
        'details': {
            'email': data.email,
            'firstName': data.firstName,
            'lastName': data.lastName,
        },
      "image":data.image
    }
    return await db.collection(firestoreKeys.USERS).doc(data.id).update(obj);
}
export async function deleteMember(id) {
    let success = true;

    try {
        const teamCollection = db.collection(firestoreKeys.TEAMS);
        const query = teamCollection.where("users","array-contains",id);
        const snapshot = await query.get();
        snapshot.forEach(doc => {
            doc.ref.update({
                users: firebase.firestore.FieldValue.arrayRemove(id)
            });
        });
    } catch (error) {
        success = false;
        ToastHelper.error('Error removing user from teams: ', error);
    }

    try {
        const sessionCollection = db.collection(firestoreKeys.SESSIONS);
        const q = sessionCollection.where("playerIds","array-contains",id);
        const response = await q.get();
        response.forEach(doc => {
            const data = doc.data();
            const updatedplayers = data.players.filter(item => item.id !== id);
            doc.ref.update({
                playerIds: firebase.firestore.FieldValue.arrayRemove(id),
                players: updatedplayers
            });
        });
    } catch (error) {
        success = false;
        ToastHelper.error('Error removing user from sessions: ', error);
    }

    try {
        const playersCollection = db.collectionGroup(firestoreKeys.PLAYERS).where("id" ,"==", id);
        const querySnapshot = await playersCollection.get();
        querySnapshot.forEach(doc => {
            doc.ref.delete();
        });
    } catch (error) {
        success = false;
        console.error('Error fetching documents: ', error);
    }

    if (success) {
        ToastHelper.success("Member removed from all teams, sessions, and Players collection");
    }
}
