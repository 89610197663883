import React, { useState, useEffect } from 'react';
import './ModalContent.css';

const ModalContent = ({ blackDom, title, mainImage, emojis, msg, isTimeUpAlert, isCommitment, isExhausted = false, isRefinement }) => {
  const [timeLeft, setTimeLeft] = useState(6);
  const [alertTime, setAlertTime] = useState(15);

  useEffect(() => {
    if (alertTime > 0) {
      const timer = setInterval(() => {
        setAlertTime(alertTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [alertTime]);

  useEffect(() => {
    if (isTimeUpAlert && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isTimeUpAlert, timeLeft]);

  const countdownStyle = {
    backgroundImage: `conic-gradient(from 0.25turn, red ${(timeLeft / 5) * 100}%, transparent 0%)`
  };

  return (
    <div>
      <div className='backdrop' />
      <div className='modal-container' style={{ backgroundColor: isTimeUpAlert || isRefinement ? '#d9d9d9' : '#fff' }}>
        <div className='alert-title' style={{ backgroundColor: isCommitment || isRefinement ? '#EF7E14' : '#dd4d2b' }}>
          {title}
        </div>
        <div className='description'>
          {msg ? <p>{msg}</p> : null}
          {emojis ? <img className='emojis' src={emojis} alt='img' /> : null}
          <div className= {`${blackDom ? "image-container-with-blackdom" : 'image-container'}`} style={{ backgroundColor: isTimeUpAlert || isRefinement ? '#fff' : '#d9d9d9' }}>
            {isCommitment || isRefinement ? (
              <img className='commitment' src={mainImage} alt='img' />
            ) : isTimeUpAlert ? (
              <div className='main-image-container'>
                <div className='circular-countdown' style={countdownStyle}></div>
                <img className='main-image' src={mainImage} alt='img' />
              </div>
            ) : isExhausted ? (
              <>
                <div className='main-image-countdown'>{alertTime}</div>
                <img className='main-image' src={mainImage} alt='img' />
              </>
            ) : (
              mainImage && <img className= {`${blackDom ? "main-image-with-blackDom" : "main-image"}`} src={mainImage} alt='img' />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalContent;
