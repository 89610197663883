import React from 'react';
import {
  Home,
  VideogameAsset,
  Dashboard,
  Store,
  Apartment,
  AccountBox,
  LibraryAdd,
  LibraryBooks,
  Groups,
  PeopleAlt,
} from '@mui/icons-material';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import LanguageIcon from '@mui/icons-material/Language';

import {
  InProgressSlideImage,
  Logo,
  PlaningSlideImage,
  ReflectionSlideImage,
  ReviewSlideImage,
  TTwoImage,
  TutorialImageFour,
  TutorialImageOne,
  TutorialImageThree,
  TutorialImageTwo,
  TutorialSlideOneNew,
  TutorialSlideTwoTennisCourt,
  leftImageSlide,
  pinkFunnel,
  funnelTutorialSlide2,
  funnelTutorialSlide3,
  funnelTutorialSlide4,
  funnelTutorialSlide5,
  funnelTutorialSlide6,
  funnelTutorialSlide7,
  funnelTutorialSlide8,
  funnelTutorialSlide9,
  funnelTutorialSlide10,
  funnelTutorialSlide11,
  executioner,
  executionerTutorialSlide2,
  executionerTutorialSlide3,
  executionerTutorialSlide4,
  bouncerTutorial,
  bouncerTutorial5, funnelTutorialSvg,

} from "../../assets/images";


export const routeKeys = Object.freeze({
  OTHER: '*',
  DEFAULT: '/',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  CREATE: '/create',
  GAMESCREEN: '/game',
  SESSIONS: '/sessions',
  AGILE_LEADER_SESSIONS: '/agile-leader-sessions',
  ORG_SESSIONS:'/organization-sessions',
  HISTORY:'/history',
  CREATE_WORK_ITEM: '/create/work-item',
  ESTIMATE_DRILL: '/elements/drill',
  WORK_ITEMS:'/work-items',
  CREATE_ORGANIZATION:'/create-organization',
  VIEW_DEPARTMENTS:'/view-departments',
  VIEW_SUB_DEPARTMENTS:'/view-sub-departments',
  USER_DETAIL:'/user-detail/:id',
  CREATE_USER:'/create-user',
  USER_LIST:'/user-list',
  ALL_USER_LIST:'/all-users',
  TEAM_LIST:'/team-list',
  USER_DASHBOARD:'/user-dashboard',
  ORGANIZATION_INFO:'/organization-info',
  PROFILE:'/profile',
  ORG_PROFILE:'/organization-profile',
  USER_PROFILE:'/user-profile',
  ORGANIZATION_ADMIN_DETAILS:'/organization-admin-details/:id',
  ORGANIZATIONS_LIST:'/organizations-list',
  METRICS:'/metrics',
  USER:'/user',
  REFINEMENT:'/refinement',
  ELEMENTS:'/elements',
  EARTH:'/elements/earth'
});

export const firestoreKeys = Object.freeze({
  SCALETEAMS:'scaleTeams',
  SESSIONS: 'sessions',
  GAMESCREEN: 'game',
  USERS: 'users',
  WORK_ITEMS: 'workItems',
  ROUNDS: 'rounds',
  ROUND_ITEMS:'roundItems',
  BALLS:'balls',
  TIMERS:'timers',
  PLAYERS:'players',
  SORTEDWORKITEMS:'sortedWorkItems',
  ORGANIZATIONS:'organizations',
  TEAMS:'teams',
  DEPARTMENTS:'departments',
  DIVISIONS:'divisions',
  METRICS:'metrics',
  USER:'user',
  PROFILE:'profile',
  ORG_PROFILE:'organization-profile',

});

export const sessionStates = Object.freeze({
  IN_LOBBY: 'in-lobby',
  PLAYING: 'playing',
  CANCELLED: 'cancelled',
  COMPLETED: 'completed'
});

export const stages = Object.freeze({
  TUTORIAL: 0,
  PLANNING: 1,
  ESTIMATION: 2,
  GAME: 3,
  REVIEW: 4,
  REFLECT: 5,
  CHALLENGE: 6,
  COMMITMENT: 7,
  REFINEMENT: 11,
  HUDDLE_STAGE:16,
  NEW_CHALLENGE: 17,
  RETRO : 18,
});

export const itemStatus = Object.freeze({
  backlog:0,
  cancel: 1,
  skipped: 2,
  completed: 3,
  active: 4,
  onHold: 6,
  reviewWorkItems: 7,
});

export const itemColors = Object.freeze({
  selectedWorkItems:"0xFF42A5F5",
  committedItems: "0xFF388E3C",
  unCommittedItems: "0xFFFFC400",
  unCompleted: "0xFFFFFF00",
  acceptedWithMissingInfo: "0xFFFF0000",
  sentBackWorkItems: "0xFFFFA500"
});

export const firstRoundStages = Object.freeze(['Planning', 'Estimation', 'Round', 'Review (Scoring)']);
export const midRoundStages = Object.freeze(['Retro & Planning', 'Estimation', 'Round', 'Review (Scoring)']);
export const lastRoundStages = Object.freeze(['Retro & Planning', 'Estimation', 'Round', 'Review', 'Reflect']);

export const AccessLevel=Object.freeze(["dashboard","user-dashboard","profile","user-profile","sessions","history","agile-sessions","organization-sessions","create-work-item","work-items","create-organization","organizations-list","organization-info","view-departments","view-sub-departments","user-list","team-list"])

export const userAccessLevel=Object.freeze(['dashboard',"user-dashboard","user-profile","history"])
export const OAAccessLevel=Object.freeze(["dashboard","profile","organization-sessions","organization-info","view-departments","view-sub-departments","user-list","team-list",'earthDrill'])
export const adminAccessLevel=Object.freeze(["dashboard","sessions","create-work-item","work-items","create-organization","organizations-list","Admin","all-users"])
export const agileLeaderAccessLevel=Object.freeze(["dashboard","agile-sessions","can-host",'earthDrill'])
export const newUserAccessLevel=Object.freeze(["dashboard","history","can-host","user-profile"])

export const navItems = Object.freeze([
  {
    name: 'Dashboard',
    key: 'dashboard',
    routeKey: routeKeys.DASHBOARD,
    accessTo:["isAgileLeader","OA","Admin"],
    icon: <Dashboard/>
  },
  {
    name: 'Elements',
    key: 'earthDrill',
    routeKey: routeKeys.ELEMENTS,
    accessTo:["isAgileLeader","OA","Admin"],
    icon: <LanguageIcon/>
  },
  {
    name: 'Dashboard',
    key: 'user-dashboard',
    routeKey: routeKeys.USER_DASHBOARD,
    accessTo:["Player"],
    icon: <Home />
  },
  {
    name: 'Profile',
    key: 'profile',
    routeKey: routeKeys.PROFILE,
    accessTo:["OA"],
    icon: <AccountBox />
  },
  {
    name: 'Profile',
    key: 'user-profile',
    routeKey: routeKeys.USER_PROFILE,
    accessTo:["Player"],
    icon: <AccountBox />
  },
  {
    name: 'History',
    key: 'agile-sessions',
    routeKey: routeKeys.AGILE_LEADER_SESSIONS,
    accessTo:["isAgileLeader"],
    icon: <VideogameAsset />
  },
  {
    name: 'History',
    key: 'sessions',
    routeKey: routeKeys.SESSIONS,
    accessTo:["Admin"],
    icon: <VideogameAsset />
  },
  {
    name: 'My Sessions',
    key: 'history',
    routeKey: routeKeys.HISTORY,
    accessTo:["Player"],
    icon: <VideogameAsset />
  },
  {
    name: 'History',
    key: 'organization-sessions',
    routeKey: routeKeys.ORG_SESSIONS,
    accessTo:["OA"],
    icon: <VideogameAsset />
  },
  {
    name: 'Create Work Item',
    key: 'create-work-item',
    routeKey: routeKeys.CREATE_WORK_ITEM,
    accessTo:["Admin"],
    icon: <LibraryAdd />
  },
  {
    name: 'Work Items',
    key: 'work-items',
    routeKey: routeKeys.WORK_ITEMS,
    accessTo:["Admin"],
    icon: <LibraryBooks />
  },
  {
    name: 'Create Organization',
    key: 'create-organization',
    routeKey: routeKeys.CREATE_ORGANIZATION,
    accessTo:["Admin"],
    icon: <Apartment />
  },
  {
    name: 'Organizations',
    key: 'organizations-list',
    routeKey: routeKeys.ORGANIZATIONS_LIST,
    accessTo:["Admin"],
    icon: <Apartment />
  },
  {
    name: 'Organization',
    key: 'organization-info',
    routeKey: routeKeys.ORGANIZATION_INFO,
    accessTo:["OA"],
    icon: <AccountBox />
  },
  {
    name: 'Departments',
    key: 'view-departments',
    routeKey: routeKeys.VIEW_DEPARTMENTS,
    accessTo:["OA"],
    icon: <Apartment />
  },
  {
    name: 'Divisions',
    key: 'view-sub-departments',
    routeKey: routeKeys.VIEW_SUB_DEPARTMENTS,
    accessTo:["OA"],
    icon: <Store />
  },
  {
    name: 'Users',
    key: 'user-list',
    routeKey: routeKeys.USER_LIST,
    accessTo:["OA"],
    icon: <PeopleAlt />
  },
  {
    name: 'Users',
    key: 'all-users',
    routeKey: routeKeys.ALL_USER_LIST,
    accessTo:["Admin"],
    icon: <RecentActorsIcon />
  },
  {
    name: 'Teams',
    key: 'team-list',
    routeKey: routeKeys.TEAM_LIST,
    accessTo:["OA","Admin"],
    icon: <Groups />
  },
]);

export const gameRules = Object.freeze([
  'Process starts and ends with the Firestarter',
  'The firestarter selection is permanent',
  'Each player must touch the same ball to score a point',
  'Your neighbor is the player that started next to you',
  'Pass to your neighbor at your own risk'
]);

export const dateFormat = 'DD/MM/YYYY';
export const timeFormat = 'HH:mm';

export const interactiveTutorialExtraSlides = [
      {
        "id":1,
        "audioPath":require("../../assets/sounds/11thSlide.mp3"),
      },
      {
        "id":2,
        "audioPath":require("../../assets/sounds/12thSlide.mp3"),
      },
      {
        "id":6,
        "audioPath":require("../../assets/sounds/ballAutoTravel.mp3"),
      },
      {
        "id":7,
        "audioPath":require("../../assets/sounds/wrap_up.mp3"),
      }
    ]


export const interactiveTutorialData = [
  {
    "id":1,
    "content":`<h4 style='font-size: 2.6rem; font-weight:bold' class='h4Style' >Welcome to Agilelements<h4 /> <Image style="padding: 14px 0px;"  wrapperClassName="wrapperImageInstance" height='175rem' fit='contain'  src=${Logo}  duration={0}/><h6 class='h6Style' style='font-size: 1.8rem; font-weight:200'>We put together this short tutorial to explain the what,  the why and the <br/> how. <br/>By the way,  you are in control.</h6>`,
    "imagePath":null,
    "audioPath":require("../../assets/sounds/slide1.mp3"),
    "showTimer":8,
    "fullwidth":true,
    "hideHeading":true,
    "hideFloatingTitle":true
  },
  // {
  //   "id":2,
  //   "content":"<h6 style='font-size: 1.8rem;' class='h6Style'>Agility is a team sport, we move ahead together. <br/> <br/>  The tutorial will only move forward when every member has double swiped.</h6>",
  //   "imagePath":TTwoImage,
  //   "audioPath":require("../../assets/sounds/slide2.mp3"),
  //   "showTimer":8,
  //   "fullwidth":false,
  //   "hideHeading":true,
  //   "hideFloatingTitle":true
  // },
  // {
  //   "id":3,
  //   "content":`<h6 class='h6Style' style='font-size: 2.2rem; font-weight:200'>To double swipe, use 1 finger and swipe 2 times.<br/> <br/>Give it a shot!</h6> <Image style="padding-top: 80px;"  wrapperClassName="wrapperImageInstance" height='175rem' fit='contain'  src=${SwipeExample}  duration={0}/>`,
  //   "imagePath":null,
  //   "audioPath":require("../../assets/sounds/slide3.mp3"),
  //   "showTimer":8,
  //   "fullwidth":true,
  //   "hideHeading":true,
  //   "hideFloatingTitle":true
  // },
  // {
  //   "id":4,
  //   "content":`<h6 style='font-size: 1.8rem;margin-bottom: 45px;' class='h6Style'>Success! <br/>You've done it!</h6><Image style="padding: 14px 0px;"  wrapperClassName="wrapperImageInstance" height='200rem' fit='contain'  src=${SuccessTick}  duration={0}/><h6 class='h6Style' style='font-size: 1.8rem; font-weight:200;margin-top: 50px'>Now let's start the tutorial.</h6>`,
  //   "imagePath":null,
  //   "audioPath":require("../../assets/sounds/slide4.mp3"),
  //   "showTimer":8,
  //   "fullwidth":true,
  //   "hideHeading":true,
  //   "hideFloatingTitle":true
  // },
  // {
  //   "id":5,
  //   "content":"<h6 style='font-size: 1.8rem;' class='h6Style'>If you see the following message on your screen after double swiping, don’t worry. The tutorial will continue once all team members have double swiped.</h6>",
  //   "imagePath":BePatientImg,
  //   "audioPath":require("../../assets/sounds/slide5.mp3"),
  //   "showTimer":8,
  //   "fullwidth":false,
  //   "hideHeading":true,
  //   "hideFloatingTitle":true
  // },
  {
    "id":6,
    "content":`
          <div>
                <h6 style='font-size: 3rem;font-weight:bold;margin-bottom: 2%;margin-top: 1%;' className='h6Style'>
                        Revealed roles
                </h6>
          </div>
        <div style="display:flex;gap:10rem">
                <div>
               
                    <br/>
                    <br/>
                    <Image style="padding: 14px 0px;"  wrapperClassName="wrapperImageInstance" height='150rem' fit='contain'  src=${leftImageSlide}  duration={0}/>
                      <div>
                      <h6 style='font-size: 1.5rem;text-align: left' class='h6Style' > 
                      <ul>
                        <li>Sets up the session</li>
                        <li>Is sworn to secrecy</li>
                      </ul>
                      </h6>
                </div>
                </div>
                <div>
               
                <br/>
                    <br/> 
                <Image style="padding: 14px 0px;"  wrapperClassName="wrapperImageInstance" height='150rem' fit='contain'  src=${TTwoImage}  duration={0}/>  
                <div>
                <h6 style='font-size: 1.5rem;text-align: left' class='h6Style' > 
                <ul>
                    <li>Creates a renewable energy source called fireballs</li>
                    <li>Collaborate</li>
                    <li>Self-Organize</li>
                    <li>Remove impediments to score</li>
                    <li>Continually improve</li>
                </ul>
                    
                </h6>
                </div>
                </div>
                </div>`,
    "audioPath":require("../../assets/sounds/slide6.mp3"),
    "showTimer":18,
    "fullwidth":true,
    "hideHeading":true,
    "hideFloatingTitle":true
  },
  {
    "id":7,
    "content":" <h4 style='font-size: 3.525rem;' class='h4Style' >The Challenge<h4 /><h6 class='h6Style'>In 3 minute rounds, <br/>make as many balls live as you can!</h6>",
    "imagePath":TutorialSlideOneNew,
    "audioPath":require("../../assets/sounds/1stSlide.mp3"),
    "showTimer":8
  },
  {
    "id":8,
    "content":" <h4 class='h4Style' >The Training Environment<h4 /><br/> <h6 style='font-size: 1.5rem;' class='h6Style'>Imagine your host found you a multi purpose room for this exercise, it’s 10 meters x 10 meters so that space wouldn’t be a problem. <br/> <br/> <br/> Approx. half of a tennis court.</h6>",
    "imagePath":TutorialSlideTwoTennisCourt,
    "audioPath":require("../../assets/sounds/2ndSlide.mp3"),
    "showTimer":8,
  },
  {
    "id":9,
    "content":"<div><h6 style='font-size: 1.5rem;animation: blinker 0s step-start forwards;' class='h6Style'>You and your teammates will : <br/><br/></h6><div><h6 style='font-size: 1.5rem;text-align: left' class='h6Style' > <span style='animation: blinker 5s step-start forwards;'>1.Do some planning, (How will you do the work?)</span> <br/><span style='animation: blinker 10s step-start forwards;'>2.Do some work, (The round)</span><br/><span style='animation: blinker 15s step-start forwards;'>3.Do some reviewing, (How did you perform?)</span><br/><span style='animation: blinker 20s step-start forwards;'>4.Do some reflecting, (How do we produce more?)</span></h6></div><br/><br/><h6 style='font-size: 1.5rem;animation: blinker 26s step-start forwards;' class='h6Style'>RINSE AND REPEAT!</h6></div>",
    "audioPath":require("../../assets/sounds/3rdSlide.mp3"),
    "imagePath":[
      {image:ReflectionSlideImage,time:0},
      {image:ReviewSlideImage,time:5},
      {image:InProgressSlideImage,time:7.5},
      {image:PlaningSlideImage,time:10}],
    "showTimer":18,
  },
  {
    "id":10,
    "content":"<h4 class='h4Style' > Each session must have a minimum <br/> of 5  team members. <br/> You will be positioned in a circle.</h4>",
    "audioPath":require("../../assets/sounds/4thSlide.mp3"),
    "imagePath":TutorialImageOne,
    "showTimer":8,
  },
  {
    "id":11,
    "content":"<h4 class='h4Style' >To score you must complete cycles!</h4>",
    "audioPath":require("../../assets/sounds/5thSlide.mp3"),
    "imagePath":TutorialImageTwo,
    "showTimer":8,
  },
  {
    "id":12,
    "content":"<h4 class='h4Style' >One member of your team will be <br/> nominated to start and end each cycle.</h4>",
    "audioPath":require("../../assets/sounds/6thSlide.mp3"),
    "imagePath":TutorialImageTwo,
    "showTimer":8,
  },
  {
    "id":13,
    "content":"<h4 class='h4Style' >This person is the firestarter.<br/> (This is a permanent role)<br/>  In this example player 2 is the firestarter.</h4>",
    "audioPath":require("../../assets/sounds/7thSlide.mp3"),
    "imagePath":TutorialImageThree,
    "showTimer":8,
  },
  {
    "id":14,
    "content":"<h4 class='h4Style' >A cycle is completed when each member of <br/> the team has touched the ball <br/> and it has returned to the firestarter.</h4>",
    "audioPath":require("../../assets/sounds/8thSlide.mp3"),
    "imagePath":TutorialImageFour,
    "showTimer":8,
  },
  {
    "id":15,
    "content":"<h4 class='h4Style' >You have now scored a point!!!!!</h4>",
    "audioPath":require("../../assets/sounds/9thSlide.mp3"),
    "imagePath":TutorialImageFour,
    "showTimer":4,
  },
  {
    "id":16,
    "content":"<h4 class='h4Style'>Score as many points as you can in the time given.</h4> ",
    "audioPath":require("../../assets/sounds/10thSlide.mp3"),
    "imagePath":null,
    "showTimer":8,
  },
  {
    "id":17,
    "content":"<h4 class='h4Style' style='color: red' >The ball may not flow to the neighbor.</h4>",
    "audioPath":require("../../assets/sounds/13thSlide.mp3"),
    "imagePath":TutorialImageFour,
    "showTimer":8,
    "hideFloatingTitle":true

  }
]

export const audios = [
  {
    "id":1,
    "audioPath":require("../../assets/sounds/planning.mp3"),
  },
  {
    "id":2,
    "audioPath":require("../../assets/sounds/estimation.mp3"),
  },
  {
    "id":3,
    "audioPath":require("../../assets/sounds/round.mp3"),
  },
  {
    "id":4,
    "audioPath":require("../../assets/sounds/review.mp3"),
  },
  {
    "id":5,
    "audioPath":require("../../assets/sounds/R&P.mp3"),
  },
  {
    "id":6,
    "audioPath":require("../../assets/sounds/challengeStage.mp3"),
  },
  {
    "id":7,
    "audioPath":require("../../assets/sounds/successOne.mp3"),
  },
  {
    "id":8,
    "audioPath":require("../../assets/sounds/successTwo.mp3"),
  },
  {
    "id":9,
    "audioPath":require("../../assets/sounds/commetmentStage.mp3"),
  },
]


export const funnelTutorial = [
  {
    "id":1,
    "title":'Funnel Tutorial',
    "subTitle": "",
    "imagePath": funnelTutorialSvg,
    "content":`<p>The Funnel engages with your customers<br/> to help your team deliver</p>`,
    "audioPath":require("../../assets/sounds/funnelTutorialSound1.mp3"),

  },
  {
    "id":2,
    "title":"Funnel controls:",
    "subTitle": "To view work items click the \"Next\" button",
    "imagePath":funnelTutorialSlide2,
    "content":"",
    "audioPath":require("../../assets/sounds/funnelTutorialSound2.mp3"),
  },
  {
    "id":3,
    "title":"",
    "subTitle": `<p>The funnel has a check list to help<br/> with work item readiness!</p>`,
    "imagePath":funnelTutorialSlide3,
    "content":"",
    "audioPath":require("../../assets/sounds/funnelTutorialSound3.mp3"),

  },
  {
    "id":4,
    "title":"",
    "subTitle": `<p>Customer, Team</p>`,
    "imagePath":funnelTutorialSlide4,
    "content":"",
    "audioPath":require("../../assets/sounds/funnelTutorialSound4.mp3"),
  },
  {
    "id":5,
    "title":"",
    "subTitle": `<p>Click <span style="font-weight: bold">"Accept" </span></p>`,
    "imagePath":funnelTutorialSlide5,
    "content":"",
    "audioPath":require("../../assets/sounds/funnelTutorialSound5.mp3"),
  },

  {
    "id":6,
    "title":"",
    "subTitle": `<p>Select the commands that allows you<br/> to proceed and help the team.</p>`,
    "imagePath":funnelTutorialSlide6,
    "content":"",
    "audioPath":require("../../assets/sounds/funnelTutorialSound6.mp3"),
  },
  {
    "id":7,
    "title":"",
    "subTitle": `<p>The Funnel can access the <span style="font-weight: bold">“Backlog”!</span></p>`,
    "imagePath":funnelTutorialSlide7,
    "content":"",
    "audioPath":require("../../assets/sounds/funnelTutorialSound7.mp3"),
  },
  {
    "id":8,
    "title":"",
    "subTitle": `<p>Now there is a place to store work <br/>items and order them.</p>`,
    "imagePath":funnelTutorialSlide8,
    "content":"",
    "audioPath":require("../../assets/sounds/funnelTutorialSound8.mp3"),
  },
  {
    "id":9,
    "title":"",
    "subTitle": `<p>Click <span style="font-weight: bold">"Accept" </span></p>`,
    "imagePath":funnelTutorialSlide6,
    "content":"",
    "audioPath":require("../../assets/sounds/funnelTutorialSound9.mp3"),
  }
]

export const executionerTutorialData=[
  {
    "id":1,
    "title":'',
    "subTitle": "",
    "imagePath": bouncerTutorial,
    "content":`<p><span style="font-weight: bolder; font-size: 30px">Bouncer Tutorial</span><br/><span>There are 3 things the bouncer role can do</span></p>`,
    "audioPath":require("../../assets/sounds/executionerSound1.mp3"),
  },
  {
    "id":2,
    "title":'Step 1',
    "subTitle": "Accept a work item",
    "imagePath": executionerTutorialSlide2,
    "content":``,
    "audioPath":require("../../assets/sounds/executionerSound2.mp3"),
    "showForwardArrow":true,
    "showBackwardArrow":false,

  },
  {
    "id":3,
    "title":'Step 2',
    "subTitle": "Deny a work item",
    "imagePath": executionerTutorialSlide3,
    "content":``,
    "audioPath":require("../../assets/sounds/executionerSound3.mp3"),
    "showForwardArrow":false,
    "showBackwardArrow":true,
  },
  {
    "id":4,
    "title":'Step 3',
    "subTitle": "Skip a work item",
    "imagePath": executionerTutorialSlide4,
    "content":``,
    "audioPath":require("../../assets/sounds/executionerSound4.mp3"),
    "showForwardArrow":true,
    "showBackwardArrow":false,

  },
  {
    "id":5,
    "title":'Step 4',
    "subTitle": "Sent back work item to funnel.",
    "imagePath": bouncerTutorial5,
    "content":``,
    "audioPath":require("../../assets/sounds/executionerSound4.mp3"),
    "showForwardArrow":false,
    "showBackwardArrow":true,
  },
]
