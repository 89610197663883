import { createTheme } from '@mui/material';

export default createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#bf4f55',
      light: '#505050',
      dark: '#1f1f34',
      contrastText: '#fff',
      text: '#fff',
      alternativeLight: '#858585'
    }
  }
});
