import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import ModalContent from './ModalContent';

const AlertComponent = ({blackDom = false,shouldLast, isCommitment,title,emojis,isTimeUpAlert,mainImage,isExhausted,msg}) => {
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    if (showModal) {
      const timeoutId = setTimeout(() => {
        setShowModal(false);
      }, shouldLast);
      return () => clearTimeout(timeoutId);
    }
  }, [showModal]);

  return (
    <div>
      {showModal &&
        createPortal(
          <ModalContent
            blackDom={blackDom}
            title={title}
            mainImage={mainImage}
            emojis={emojis}
            msg={msg}
            isTimeUpAlert={isTimeUpAlert}
            isCommitment={isCommitment}
            isExhausted={isExhausted}
          />,
          document.getElementById('alert-modal')
        )}
    </div>
  );
};

export default AlertComponent;
