import React from 'react';
import {useAtom} from 'jotai';
import {useAuthService} from '../../../shared/services';
import {useNavigation, useToast} from '../../../shared/hooks';
import {getOrganization, getUser} from '../../../shared/services/userService';
import {routeKeys} from '../../../shared/utils/constants';
import {userRecordAtom} from '../../../shared/state/atoms';
import {setUser} from '../../../redux/userSlice';
import {useDispatch} from "react-redux";
import {ToastHelper} from "../../../helpers/toast.helper";

export default function useLoginForm(onClose) {
    const {push}=useNavigation();
    const dispatch=useDispatch();
    const [, setUserRecord]=useAtom(userRecordAtom);
    const {showSuccess, showError}=useToast();
    const {signIn, sendForgotPasswordEmail, signInWithToken}=useAuthService();
    const [isBusy, setIsBusy]=React.useState(false);

    const handleLogin= async ({email, password})=>{
        setIsBusy(true);
        try {
            const authRes = await signIn(email, password);
            const res = await getUser(authRes.user.uid);
            const org = await getOrganization(res?.organizationId);

            if (!res.disable) {
                dispatch(setUser(res));
                setUserRecord({ organization: { ...org }, ...res });
                push(routeKeys.DASHBOARD);
            } else {
                showError("You don't have the relevant permissions to access this site.");
            }
        } catch (err) {
            if (err.code === "auth/user-disabled") {
                ToastHelper.error("User has been disabled by the administrator");
            } else {
                ToastHelper.error(err.message);
            }
        } finally {
            setIsBusy(false);
        }

        // return signIn(email, password)
        //     .then((authRes)=>
        //         getUser(authRes.user.uid).then((res)=>getOrganization(res?.organizationId).then((org)=>     {
        //             if (!res.disable) {
        //                 dispatch(setUser(res));
        //                 setUserRecord({organization: {...org},...res });
        //                 push(routeKeys.DASHBOARD);
        //             } else {
        //                 showError("You don't have the relevant permissions to access this site.");
        //             }
        //         })
        //    )
        //     )
        //     .catch((err)=> {
        //
        //         if (err.code === "auth/user-disabled") {
        //             ToastHelper.error("User has been disabled by the administrator")
        //         }else {
        //             ToastHelper.error(err.message)
        //         }
        //     })
        //     .finally(()=>setIsBusy(false));
    };

    const handleLoginWithLinkedin=(token)=>{
        setIsBusy(true);

        return signInWithToken(token)
            .then((authRes)=>{

                    return getUser(authRes.user.uid).then((res)=>{
                        // if (res.permissions.isAdmin) {
                        // return getUser(authRes.user.uid).then(data=>{
                            dispatch(setUser(res));
                            setUserRecord({...res});
                            push(routeKeys.DASHBOARD);
                        // })

                        // } else {
                        //     showError("You don't have the relevant permissions to access this site.");
                        // }
                    })
                }
            )
            .catch((err)=>showError(err.message))
            .finally(()=>setIsBusy(false));
    };


    const resetPassword=({email})=>{
        return sendForgotPasswordEmail(email)
            .then(()=>{
                showSuccess("We've sent you an email to reset your password.");
                onClose();
            })
            .catch((err)=>showError(err.message));
    };

    return {isBusy, handleLogin, resetPassword ,handleLoginWithLinkedin};
}
