import { firestoreKeys } from '../utils/constants';
import {db} from './firebase';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import firebase from "firebase/compat";


export async function createWorkItem(data) {
    return await db.collection(firestoreKeys.WORK_ITEMS).doc(data.id).set(data);
}

export async function commitWorkItem(sessionId,workItem) {
    const workItemData = {
        ...workItem,
        meta: {
            createdDate: firebase.firestore.Timestamp.fromDate(new Date())
        }
    };
    return  await db.collection(firestoreKeys.SESSIONS).doc(sessionId).collection("workItems").doc(workItemData.id).set(workItemData);
}
export async function deleteWorkItems(id){
    return await db.collection(firestoreKeys.WORK_ITEMS).doc(id).delete();
}
