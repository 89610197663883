import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "jotai";
import { CircularProgress, Box, ThemeProvider } from "@mui/material";
import { darkTheme, lightTheme } from "./themes";
import { ToastContainer, toast } from 'react-toastify';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import 'react-toastify/dist/ReactToastify.css';

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "../shared/styles/global.css";
import "../app/App.css";
import {routeKeys} from "../shared/utils/constants";
import OAFillDetailPage from "../hubModules/OAFillDetailPage/OAFillDetailPage";
import UserDetailPage from "../hubModules/userFillDetailPage/userDetailPage";


const TheLayout = React.lazy(() => import("../containers/TheLayout"));

function App() {
  const [isDarkMode, setDarkMode] = React.useState(false);

  return (
    <Provider>
      <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
        <BrowserRouter>
          <React.Suspense
            fallback={
              <Box className="globalLoader" bgcolor={"primary.light"}>
                <CircularProgress />
              </Box>
            }
          >
            <Switch>
              <Route exact path="/linkedin" component={LinkedInCallback} />
              <Route exact path={routeKeys.ORGANIZATION_ADMIN_DETAILS} component={OAFillDetailPage} />
              <Route exact path={routeKeys.USER_DETAIL} component={UserDetailPage} />
              <Route
                path="/"
                name="Home"
                render={(props) => <TheLayout {...props} setDarkMode={setDarkMode} />}
              />

            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </ThemeProvider>
        <ToastContainer />
    </Provider>
  );
}

export default App;
